import config from './config';
import i18n from '../i18n';

export const status = {
    OK: 200,
    CREATED: 201,
    NO_STORE: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    MANY_REQUEST: 429,
    EXPIRED_SESSION: 503,
    ERROR: 500,
};

export const endpoint = {
    // --------------------LUID------------------------
    PROFILE: `${config.ACCOUNT_UID}/profile`,

    // --------------------LF-------------------------
    STORE_INFO: `${config.API_V2_0}/store/info`,
    GET_API_KEY_INFO: `${config.API_V2_0}/store/api-key-info`,
    RE_CREATE_API_KEY: `${config.API_V2_0}/store/re-create-api-key`,
    STORE_UPDATE: `${config.API_V2_0}/store/update`,
    STORE_SHOW: `${config.API_V2_0}/store/show`,
    AFFILIATE_SETTING_GET: `${config.API_V2_0}/store/get-info-store-setting`,
    AFFILIATE_SETTING_UPDATE: `${config.API_V2_0}/store/update-info-store-setting`,
    LIST_SENDER_EMAIL: `${config.API_V2_0}/store/list-sender-email`,
    CREATE_SENDER_EMAIL: `${config.API_V2_0}/store/create-sender-email`,
    UPDATE_SENDER_EMAIL: `${config.API_V2_0}/store/update-sender-email`,
    SHOW_SENDER_EMAIL: `${config.API_V2_0}/store/show-sender-email`,
    DELETE_SENDER_EMAIL: `${config.API_V2_0}/store/delete-sender-email`,
    MAKE_AS_DEFAULT_SENDER_EMAIL: `${config.API_V2_0}/store/make-as-default-sender-email`,
    VERIFY_SENDER_EMAIL: `${config.API_V2_0}/store/verify-sender-email`,
    VERIFY_MULTI_SENDER_EMAIL: `${config.API_V2_0}/store/verify-multi-sender-email`,
    DELETE_MULTI_SENDER_EMAIL: `${config.API_V2_0}/store/delete-multi-sender-email`,
    GET_AFFILIATE_DASHBOARD_SETTING: `${config.API_V2_0}/store/get-info-store-setting`,
    GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC: `${config.API_V2_0}/store/get-affiliate-dashboard-setting`,
    GET_BLOCK_IP_SETTING: `${config.API_V2_0}/store/get-info-store-setting`,
    UPDATE_BLOCK_IP_SETTING: `${config.API_V2_0}/store/update-info-store-setting`,
    GET_AFFILIATE_CHANGE_GROUP_SETTING: `${config.API_V2_0}/store/get-info-store-setting`,
    UPDATE_AFFILIATE_CHANGE_GROUP_SETTING: `${config.API_V2_0}/store/update-info-store-setting`,
    UPLOAD_LOGO_STORE: config.API_FILE_CDN,
    UPLOAD_ICON_STORE: config.API_FILE_CDN,
    UPDATE_AFFILIATE_DASHBOARD_SETTING: `${config.API_V2_0}/store/update-info-store-setting`,
    VERIFY_DOMAIN_AFFILIATE: `${config.API_V2_0}/store/verify-domain`,
    GET_TEMPLATE_EMAIL: `${config.API_V2_0}/store/get-template-email`,
    UPDATE_TEMPLATE_EMAIL: `${config.API_V2_0}/store/update-template-email`,
    GET_COOKIE_SETTING: `${config.API_V2_0}/store/get-cookie-setting`,
    SET_COOKIE_SETTING: `${config.API_V2_0}/store/update-cookie-setting`,
    GET_REQUEST_WITHDRAW_SETTING: `${config.API_V2_0}/store/get-request-withdraw-setting`,

    // ---------------------AFFILIATE----------------------
    AFFILIATE_LIST: `${config.API_V2_0}/affiliate/list`,
    AFFILIATE_CREATE: `${config.API_V2_0}/affiliate/create`,
    AFFILIATE_SHOW: `${config.API_V2_0}/affiliate/show`,
    AFFILIATE_UPDATE: `${config.API_V2_0}/affiliate/update`,
    AFFILIATE_DELETE: `${config.API_V2_0}/affiliate/delete`,
    AFFILIATE_EXPORT: `${config.API_V2_0}/affiliate/export-affiliate`,
    AFFILIATE_DELETE_MULTI: `${config.API_V2_0}/affiliate/delete-multi`,
    AFFILIATE_UPDATE_STATUS_MULTI: `${config.API_V2_0}/affiliate/update-status-multi`,
    UPDATE_OTHER_AFFILIATE: `${config.API_V2_0}/affiliate/update-other`,
    UPDATE_MULTI_AFFILIATE: `${config.API_V2_0}/affiliate/update-multi-affiliate`,
    GET_LEVEL_AFFILIATE: `${config.API_V2_0}/affiliate/get-level-affiliate`,
    IMPORT_AFFILIATE: `${config.API_V2_0}/affiliate/import-affiliate`,
    AFFILIATE_PASSED_INVOICE: `${config.API_V2_0}/affiliate/passed-invoice-affiliate`,
    CREATE_MULTI_AFFILIATE_INVOICE_MONTH: `${config.API_V2_0}/affiliate/create-month-invoice-multi-affiliate`,
    LIST_AFFILIATE_REF: `${config.API_V2_0}/affiliate/list-ref`,
    UPDATE_USER_DATA: `${config.API_V2_0}/affiliate/update-user-data`,

    // ---------------------AFFILIATE GROUP----------------------
    AFFILIATE_GROUP_LIST: `${config.API_V2_0}/affiliate-group/list`,
    AFFILIATE_GROUP_CREATE: `${config.API_V2_0}/affiliate-group/create`,
    AFFILIATE_GROUP_SHOW: `${config.API_V2_0}/affiliate-group/show`,
    AFFILIATE_GROUP_UPDATE: `${config.API_V2_0}/affiliate-group/update`,
    AFFILIATE_GROUP_DELETE: `${config.API_V2_0}/affiliate-group/delete`,
    DELETE_MULTI_AFFILIATE_GROUP: `${config.API_V2_0}/affiliate-group/delete-multi`,
    AFFILIATE_GROUP_MAKE_AS_DEFAULT: `${config.API_V2_0}/affiliate-group/make-as-default`,

    // ---------------------COMMISSION----------------------
    COMMISSION_LIST: `${config.API_V2_0}/commission/list`,
    COMMISSION_CREATE: `${config.API_V2_0}/commission/create`,
    COMMISSION_SHOW: `${config.API_V2_0}/commission/show`,
    COMMISSION_UPDATE: `${config.API_V2_0}/commission/update`,
    UPDATE_STATUS_COMMISSION: `${config.API_V2_0}/commission/update-status-commission`,
    BACK_TO_PENDING_STATUS_COMMISSION: `${config.API_V2_0}/commission/back-to-pending-commission`,
    COMMISSION_DELETE: `${config.API_V2_0}/commission/delete`,
    COMMISSION_EXPORT: `${config.API_V2_0}/commission/export-commission`,
    EXPORT_CONFIRM_COMMISSION: `${config.API_V2_0}/commission/export-confirm-commission`,
    COMMISSION_DELETE_MULTI: `${config.API_V2_0}/commission/delete-multi-commission`,
    COMMISSION_UPDATE_STATUS: `${config.API_V2_0}/commission/update-status-multi-commission`,
    APPROVE_MULTI_COMMISSION: `${config.API_V2_0}/commission/approve-multi-commission`,
    INVOICE_MULTI_COMMISSION: `${config.API_V2_0}/commission/invoice-multi-commission`,
    CANCEL_MULTI_COMMISSION: `${config.API_V2_0}/commission/cancel-multi-commission`,
    GROUP_INVOICED_MULTI_COMMISSION: `${config.API_V2_0}/commission/group-invoiced-multi-commission`,
    CONFIRM_MULTI_COMMISSION: `${config.API_V2_0}/commission/confirm-multi-commission`,
    APPROVE_COMMISSION: `${config.API_V2_0}/commission/approve-commission`,
    INVOICE_COMMISSION: `${config.API_V2_0}/commission/invoice-commission`,
    CANCEL_COMMISSION: `${config.API_V2_0}/commission/cancel-commission`,
    CONFIRM_COMMISSION: `${config.API_V2_0}/commission/confirm-commission`,
    RECHECK_COMMISSION: `${config.API_V2_0}/commission/re-check-commission`,
    TOP_COMMISSION: `${config.API_V2_0}/commission/top-commission`,
    TOP_TOTAL_COMMISSION: `${config.API_V2_0}/commission/top-total-commission`,

    // ---------------------COMMISSION PLAN----------------------
    COMMISSION_PLAN_LIST: `${config.API_V2_0}/commission-plan/list`,
    COMMISSION_PLAN_CREATE: `${config.API_V2_0}/commission-plan/create`,
    COMMISSION_PLAN_SHOW: `${config.API_V2_0}/commission-plan/show`,
    SHOW_COMMISSION_PLAN_DEFAULT: `${config.API_V2_0}/commission-plan/get-commission-plan-default`,
    COMMISSION_PLAN_UPDATE: `${config.API_V2_0}/commission-plan/update`,
    COMMISSION_PLAN_DELETE: `${config.API_V2_0}/commission-plan/delete`,
    COMMISSION_PLAN_MAKE_AS_DEFAULT: `${config.API_V2_0}/commission-plan/make-as-default`,

    // ---------------------STAFF----------------------
    STAFF_LIST: `${config.API_V2_0}/staff/list`,
    STAFF_CREATE: `${config.API_V2_0}/staff/create`,
    STAFF_SHOW: `${config.API_V2_0}/staff/show`,
    STAFF_UPDATE: `${config.API_V2_0}/staff/update`,
    STAFF_DELETE: `${config.API_V2_0}/staff/delete`,
    DELETE_MULTI_STAFF: `${config.API_V2_0}/staff/delete-multi`,

    // ---------------------STAFF----------------------
    INTEGRATE_APP_LIST: `${config.API_V2_0}/integration/list`,
    INTEGRATE_APP_CREATE: `${config.API_V2_0}/integration/create`,
    INTEGRATE_APP_SHOW: `${config.API_V2_0}/integration/show`,
    INTEGRATE_APP_UPDATE: `${config.API_V2_0}/integration/update`,
    UPDATE_STATUS_INTEGRATE_APP: `${config.API_V2_0}/integration/update-status`,
    INTEGRATE_APP_DELETE: `${config.API_V2_0}/integration/delete`,
    INTEGRATE_APP_HARAVAN: `${config.API_V2_0}/oauth/haravan`,
    INTEGRATE_APP_SAPO: `${config.API_V2_0}/oauth/sapo`,
    GET_ACCESS_SAPO: `${config.API_V2_0}/oauth/sapo-access`,
    INTEGRATE_APP_NHANH: `${config.API_V2_0}/oauth/nhanh`,
    GET_ACCESS_NHANH: `${config.API_V2_0}/oauth/nhanh-access`,
    INTEGRATE_APP_SHOPIFY: `${config.API_V2_0}/oauth/shopify`,
    GET_ACCESS_SHOPIFY: `${config.API_V2_0}/oauth/shopify-access`,
    INTEGRATE_APP_SHOPIFY_WEBHOOK: `${config.API_V2_0}/oauth/shopify-webhook`,
    INTEGRATE_APP_GETFLY_WEBHOOK: `${config.API_V2_0}/oauth/getfly-webhook`,

    // ---------------------TRAFFIC---------------------
    TRAFFIC_LIST: `${config.API_V2_0}/traffic/list`,
    LIST_ORDER: `${config.API_V2_0}/traffic/list-order`,
    LIST_LEAD: `${config.API_V2_0}/traffic/list-lead`,
    LIST_NEW_CUSTOMER: `${config.API_V2_0}/traffic/list-new-customer`,
    LIST_PRODUCT: `${config.API_V2_0}/traffic/list-product`,
    LIST_VISITOR: `${config.API_V2_0}/traffic/list-visitor`,
    TRAFFIC_CREATE: `${config.API_V2_0}/traffic/create`,
    TRAFFIC_SHOW: `${config.API_V2_0}/traffic/show`,
    TRAFFIC_UPDATE: `${config.API_V2_0}/traffic/update`,
    TRAFFIC_DELETE: `${config.API_V2_0}/traffic/delete`,
    TRAFFIC_CALCULATE_COMMISSION: `${config.API_V2_0}/traffic/calc-commission-for-traffic`,
    TRAFFIC_RE_CALCULATE_COMMISSION: `${config.API_V2_0}/traffic/re-calc-commission-for-traffic`,
    TRAFFIC_CANCEL_COMMISSION: `${config.API_V2_0}/traffic/cancel-commission-for-traffic`,

    // ---------------------INVOICE---------------------
    LIST_INVOICE: `${config.API_V2_0}/invoice/list`,
    CREATE_INVOICE: `${config.API_V2_0}/invoice/create`,
    SHOW_INVOICE: `${config.API_V2_0}/invoice/show`,
    UPDATE_INVOICE: `${config.API_V2_0}/invoice/update`,
    DELETE_INVOICE: `${config.API_V2_0}/invoice/delete`,
    EXPORT_INVOICE: `${config.API_V2_0}/invoice/export-invoice`,
    EXPORT_COMMISSION_INVOICE: `${config.API_V2_0}/invoice/export-commission-invoice`,
    DELETE_MULTI_INVOICE: `${config.API_V2_0}/invoice/delete-multi-invoice`,
    INVOICE_UPDATE_STATUS: `${config.API_V2_0}/invoice/update-status-multi-invoice`,
    UPDATE_STATUS_INVOICE: `${config.API_V2_0}/invoice/update-status-invoice`,
    EXPORT_BANK_INFO_AFFILIATE: `${config.API_V2_0}/invoice/export-bank-info-affiliate`,
    SEND_MAIL_CONFIRM_INVOICE: `${config.API_V2_0}/invoice/send-mail-confirm-invoice`,
    CREATE_REQUEST_WITHDRAW: `${config.API_V2_0}/invoice/create-request-withdraw`,
    UPDATE_NOTE_INVOICE: `${config.API_V2_0}/invoice/update-note-invoice`,

    // ---------------------STATISTICS------------------
    DAILY_REPORT: `${config.API_V2_0}/statistic/daily-report`,
    DAILY_REPORT_PUBLISHER: `${config.API_V2_0}/statistic/daily-report-publisher`,
    EXPORT_STATISTICS: `${config.API_V2_0}/statistic/export-statistics`,

    // ---------------------PUBLISHER-------------------
    PUBLISHER_LOGIN: `${config.API_V2_0}/publisher/login`,
    PUBLISHER_REGISTER: `${config.API_V2_0}/publisher/register`,
    PUBLISHER_FORGOT_PASSWORD: `${config.API_V2_0}/publisher/forgot-password`,
    PUBLISHER_RESET_PASSWORD: `${config.API_V2_0}/publisher/reset-password`,
    CAMPAIGN_SHARE_DETAIL: `${config.API_V2_0}/publisher/campaign-share-detail`,

    // ---------------------CAMPAIGN---------------------
    LIST_CAMPAIGN: `${config.API_V2_0}/campaign/list`,
    LIST_CAMPAIGN_FILTER: `${config.API_V2_0}/campaign/list`,
    CREATE_CAMPAIGN: `${config.API_V2_0}/campaign/create`,
    SHOW_CAMPAIGN: `${config.API_V2_0}/campaign/show`,
    UPDATE_CAMPAIGN: `${config.API_V2_0}/campaign/update`,
    DELETE_CAMPAIGN: `${config.API_V2_0}/campaign/delete`,
    UPLOAD_IMAGE_CAMPAIGN: config.API_FILE_CDN,
    UPLOAD_IMAGE_PROFILE_CAMPAIGN: config.API_FILE_CDN,
    GET_SHORT_LINK_CAMPAIGN: `${config.API_V2_0}/campaign/get-short-link`,

    // ---------------------COUPON_CODE------------------
    LIST_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/list`,
    CREATE_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/create`,
    SHOW_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/show`,
    UPDATE_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/update`,
    DELETE_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/delete`,
    SYNC_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/sync-coupon`,
    DELETE_MULTI_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/delete-multi`,
    SYNC_MULTI_COUPON_CODE: `${config.API_V2_0}/affiliate-coupon/sync-multi`,

    // ---------------------CUSTOMER------------------
    LIST_CUSTOMER: `${config.API_V2_0}/customer/list`,
    CREATE_CUSTOMER: `${config.API_V2_0}/customer/create`,
    SHOW_CUSTOMER: `${config.API_V2_0}/customer/show`,
    UPDATE_CUSTOMER: `${config.API_V2_0}/customer/update`,
    DELETE_CUSTOMER: `${config.API_V2_0}/customer/delete`,
    DELETE_MULTI_CUSTOMER: `${config.API_V2_0}/customer/delete-multi`,
    EXPORT_CUSTOMER: `${config.API_V2_0}/customer/export-customer`,

    // ---------------------LINKS PUBLISHER------------------
    LIST_LINK: `${config.API_V2_0}/publisher-link/list`,
    SHOW_LINK: `${config.API_V2_0}/publisher-link/show`,
    CREATE_LINK_PUBLISHER: `${config.API_V2_0}/publisher-link/create`,
    DELETE_LINK_PUBLISHER: `${config.API_V2_0}/publisher-link/delete`,

    // ---------------------REQUEST INVOICE------------------
    LIST_REQUEST_INVOICE: `${config.API_V2_0}/request-invoice/list`,
    APPROVED_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/approved-invoice-request`,
    REJECT_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/reject-invoice-request`,
    CANCEL_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/cancel-invoice-request`,
    SHOW_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/show`,
    APPROVED_MULTI_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/approved-multi-invoice-request`,
    CANCEL_MULTI_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/cancel-multi-invoice-request`,
    REJECT_MULTI_INVOICE_REQUEST: `${config.API_V2_0}/request-invoice/reject-multi-invoice-request`,

    // ---------------------ACTIVITY------------------
    LIST_ACTIVITY: `${config.API_V2_0}/activity/list`,

};

export default {
    MY_INFO: 'XY3J6326-kCAYN10v',
    ERROR: 'ERROR',
    TYPE_SAVE: {
        SAVE_DRAF: 'SAVE_DRAF',
        SAVE_POST: 'SAVE_POST',
    },
    COOKIE: {
        SSID: 'SSID',
        STORE_ID: 'STORE_ID',
        PUBLISHER: 'PUBLISHER',
        EXPIRE: 365,
        STORE_ALIAS: 'STORE_ALIAS',
        REF: 'REF',
    },
    LOCAL_FORAGE: {
        COUNTRY_KEY: 'COUNTRIES',
        STATE_KEY: 'STATES',
        DISTRICT_KEY: 'DISTRICTS',
        WARD_KEY: 'WARDS',
        USER_INFO: 'USER_INFO',
        RECENT_PARTNRER_CODE: 'RECENT_PARTNRER_CODE',
        FLOW_DRAFT_INFO: 'FLOW_DRAFT_INFO',
        FAN_PAGE_LIST: 'FAN_PAGE_LIST',
    },
    LADIFLOW_PRICE: 'https://www.ladiflow.vn/banggia',
    VER: '2.0',
    DEFAULT_FORMAT_DATE_TIME: 'DD/MM/YYYY HH:mm',
    DEFAULT_DATE: 'DD/MM/YYYY',
    DEFAULT_FORMAT_DATE: 'dd/MM/yyyy',
    REPORT_OVERTIME_FORMAT_DATE: 'dd/MM',
    DEFAULT_DATE_PICKER_FORMAT_DATE: 'dd-MM-yyyy',
    DEFAULT_TIME: 'HH:mm',
    DB_DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm:ss',
    DEFAULT_DATE_YEAR: 'YYYY/MM/DD',
    DEFAULT_SES: {
        ID: 'DEFAULT_SES',
        NAME: 'Email Testing',
    },
    PAGINATION: {
        DEFAULT_LIMIT: 10,
        LIMIT_OPTIONS: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
        ],
        LIMIT_OPTIONS_TEST: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
            { value: 1000, name: 1000 },
        ],
    },
    STATUS_OPTIONS: [
        { value: 1, name: `${i18n.t('STATUS.ACTIVE')}` },
        { value: 0, name: `${i18n.t('STATUS.INACTIVE')}` },
    ],
    FORM_MODE: {
        CREATE: 'CREATE',
        EDIT: 'EDIT',
        VIEW: 'VIEW',
    },
    APP: {
        LP: 'lp',
        LF: 'lf',
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
    },
    MODE: {
        TEST: 0,
        PRODUCTION: 1,
    },
    GENDER: {
        MALE: 'male',
        FEMALE: 'female',
    },
    WHITE_LOGO: 'https://w.ladicdn.com/source/v3/logo_ladiflow_neg.svg',
    COLOR_LOGO: 'https://w.ladicdn.com/source/v3/logo_ladiflow_origin.svg',
    DOMAIN_CDN: 'https://w.ladicdn.com',
    DOMAIN_S_CDN: 'https://s.ladicdn.com',
    RESIZE: {
        THUMB: 's150x150',
        MEDIUM: 's600x600',
    },
    PKG: {
        STARTER: 'STARTER',
        PREMIUM: 'PREMIUM',
    },
    COMMON: {
        DELETE_TYPE: {
            SINGLE: 'SINGLE',
        },
    },
    CUSTOMER: {
        ACTION_MULTI_CUSTOMER: {
            ADD_TAG: 'ADD_TAG',
            REMOVE_TAG: 'REMOVE_TAG',
            SUBSCRIBE_TO_SEQUENCE: 'SUBSCRIBE_TO_SEQUENCE',
            UN_SUBSCRIBE_TO_SEQUENCE: 'UN_SUBSCRIBE_TO_SEQUENCE',
            ADD_CUSTOM_FIELD: 'ADD_CUSTOM_FIELD',
            REMOVE_CUSTOM_FIELD: 'REMOVE_CUSTOM_FIELD',
            DELETE: 'DELETE',
        },
        GENDER: {
            MALE: 1,
            FEMALE: 2,
            UNDEFINE: 3,
        },
        CUSTOM_FIELD_GROUP: {
            BASIC: {
                name: `${i18n.t('CUSTOMERS.BASIC')}`,
                value: 1,
            },
            CUSTOM: {
                name: `${i18n.t('CUSTOMERS.CUSTOM')}`,
                value: 2,
            },
        },
        STATUS: {
            SUBSCRIBER: 'SUBSCRIBER',
            UNSUBSCRIBE: 'UNSUBSCRIBE',
        },
        TAG: {
            TYPES: {
                CUSTOMER: {
                    code: 'CUSTOMER',
                    name: i18n.t('TAGS.TYPE_CUSTOMER'),
                },
            },
        },
        EMAIL_STATUS: {
            BOUNCE: {
                code: 'BOUNCE',
                name: 'Bounce ',
            },
            COMPLAINT: {
                code: 'COMPLAINT',
                name: 'Complaint',
            },
        },
    },
    DEFAULT_FIELD: {
        FIRST_NAME: {
            name: 'first_name',
        },
        LAST_NAME: {
            name: 'last_name',
        },
        FULL_NAME: {
            name: 'full_name',
        },
        EMAIL: {
            name: 'email',
        },
        PHONE: {
            name: 'phone',
        },
        REGISTER_TIME: {
            name: 'register_time',
        },
        // EXPIRE_TIME: {
        //     name: 'expire_time',
        // },
        SUBSCRIBED_AT: {
            name: 'subscribed_at',
        },
        BIRTHDAY: {
            name: 'birth_day',
        },
    },
    CUSTOM_FIELD: {
        TYPES: {
            CUSTOMER: 'CUSTOMER',
            FLOW: 'FLOW',
            FUNNEL: 'FUNNEL',
        },
        GROUPS: {
            COMMON: {
                code: 'COMMON',
                name: i18n.t('CUSTOM_FIELDS.COMMON'),
            },
        },
        DATA_TYPES: {
            TEXT_INPUT: {
                code: 'TEXT_INPUT',
                name: i18n.t('CUSTOM_FIELDS.TEXT_INPUT'),
            },
            TEXT_AREA: {
                code: 'TEXT_AREA',
                name: i18n.t('CUSTOM_FIELDS.TEXT_AREA'),
            },
            NUMBER: {
                code: 'NUMBER',
                name: i18n.t('CUSTOM_FIELDS.NUMBER'),
            },
            DATE: {
                code: 'DATE',
                name: i18n.t('CUSTOM_FIELDS.DATE'),
            },
            DROPDOWN: {
                code: 'DROPDOWN',
                name: i18n.t('CUSTOM_FIELDS.DROPDOWN'),
            },
            CHECK_BOX: {
                code: 'CHECK_BOX',
                name: i18n.t('CUSTOM_FIELDS.CHECK_BOX'),
            },
            TAG: {
                code: 'TAG',
                name: i18n.t('CUSTOM_FIELDS.TAG'),
            },
            BOOLEAN: {
                code: 'BOOLEAN',
                name: i18n.t('CUSTOM_FIELDS.BOOLEAN'),
            },
            COUPON: {
                code: 'COUPON',
                name: i18n.t('CUSTOM_FIELDS.COUPON'),
            },
            AUTO_INCREMENT: {
                code: 'AUTO_INCREMENT',
                name: i18n.t('CUSTOM_FIELDS.AUTO_INCREMENT'),
            },
            LIST: {
                code: 'LIST',
                name: i18n.t('CUSTOM_FIELDS.LIST'),
            },
        },
    },
    TAG: {
        TYPES: {
            GROWTH_TOOL: {
                code: 'GROWTH_TOOL',
                name: i18n.t('TAGS.GROWTH_TOOL'),
            },
            BROADCAST: {
                code: 'BROADCAST',
                name: i18n.t('TAGS.BROADCAST'),
            },
            SEQUENCE: {
                code: 'SEQUENCE',
                name: i18n.t('TAGS.SEQUENCE'),
            },
        },
    },
    FLOW_TAG: {
        TYPES: {
            FLOW: 'FLOW',
            BROADCAST: 'BROADCAST',
        },
    },
    FLOW: {
        TIMER_UNIT: {
            // IMMEDIATELY: {
            //     code: 'IMMEDIATELY',
            //     name: i18n.t('SEQUENCES.IMMEDIATELY'),
            // },
            MINUTE: {
                code: 'MINUTE',
                name: i18n.t('CONFIGS.MINUTE'),
            },
            HOUR: {
                code: 'HOUR',
                name: i18n.t('CONFIGS.HOUR'),
            },
            DAY: {
                code: 'DAY',
                name: i18n.t('CONFIGS.DAY'),
            },
        },
        FLOW_STATUS: {
            DRAF: {
                code: 'DRAF',
                name: i18n.t('FLOWS.DRAF'),
            },
            PUBLISHED: {
                code: 'PUBLISHED',
                name: i18n.t('FLOWS.PUBLISHED'),
            },
        },
        SCHEDULE_STATUS: {
            ON: 'on',
            OFF: 'off',
        },
        DEFAULT_FLOW_TYPE: 'MESSENGER',
        STATUS: {
            DRAF: 'DRAF',
            PREVIEW: 'PREVIEW',
            PUBLISHED: 'PUBLISHED',
        },
        TYPE: {
            MESSENGER: 'MESSENGER',
            SMS: 'SMS',
            FLOW: 'FLOW',
            EMAIL: 'EMAIL',
        },
        SMS: {
            MAX_BUTTON_ALLOW: 1,
        },
        EMAIL: {
            MAX_BUTTON_ALLOW: 1,
        },
        ZALO: {
            COVER: {

            },
        },
        INSTAGRAM: {
            APP_ID: '1088147481959486',
            SCOPE: 'user_profile,user_media',
            APP_SECRET: 'ea6d7ae034ed0102490df0fc87dccb11',
        },
        FACEBOOK: {
            APP_ID: '483209825919585',
            MAX_BUTTON_ALLOW: 3,
            MAX_BUTTON_EMAIL: 1,
            MAX_BUTTON_CONDITION: 1,
            MAX_QUICK_REPLY_ALLOW: 13,
            MAX_ITEM_LIST_ALLOW: 4,
            // eslint-disable-next-line max-len
            // DEFAULT_USER_PERMISSION: 'email,manage_pages,pages_messaging,pages_messaging_subscriptions,public_profile,publish_pages,pages_read_user_content,pages_manage_metadata',
            // eslint-disable-next-line max-len
            DEFAULT_USER_PERMISSION: 'email,public_profile,pages_show_list,pages_manage_metadata,pages_messaging,pages_read_user_content,pages_manage_engagement,pages_read_engagement,leads_retrieval,pages_manage_ads,ads_management,instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights',
            BUTTON_SIZE: {
                SMALL: {
                    code: 'standard',
                    name: i18n.t('FLOWS.BUTTON_SIZE_SMALL'),
                },
                MEDIUM: {
                    code: 'large',
                    name: i18n.t('FLOWS.BUTTON_SIZE_MEDIUM'),
                },
                LARGE: {
                    code: 'xlarge',
                    name: i18n.t('FLOWS.BUTTON_SIZE_LARGE'),
                },
            },
            BUTTON_COLOR: {
                BLUE: {
                    code: 'blue',
                    name: i18n.t('FLOWS.BUTTON_COLOR_BLUE'),
                },
                WHITE: {
                    code: 'white',
                    name: i18n.t('FLOWS.BUTTON_COLOR_WHITE'),
                },
            },
        },
        EMBED_BUTTON: {
            BUTTON_BACKGROUND_COLOR_DEFAULT: '#12CE66',
            BUTTON_TEXT_COLOR_DEFAULT: '#FFFFFF',
            BUTTON_TEXT_DEFAULT: i18n.t('FLOWS.BUTTON_TEXT_DEFAULT'),
            MAX_LENGTH: 25,
            BLACK: '#000000',
            WHILE: '#FFFFFF',
        },
    },
    SEGMENT: {
        SWITCH_AND_OR: {
            AND: 'AND',
            OR: 'OR',
        },
        TYPE_SUBMIT: {
            CREATE: 'CREATE',
            UPDATE: 'UPDATE',
            APPLY: 'APPLY',
        },
    },
    TIME: {
        NOW: {
            code: 'NOW',
            name: 'Ngay lập tức',
        },
        SECONDS: {
            code: 'SECONDS',
            name: 'Giây',
        },
        MINUTE: {
            code: 'MINUTE',
            name: 'Phút',
        },
    },
    MESSENGER_TAG: {
        CONFIRMED_EVENT_UPDATE: {
            code: 'CONFIRMED_EVENT_UPDATE',
            name: i18n.t('CONFIGS.CONFIRMED_EVENT_UPDATE'),
        },
        POST_PURCHASE_UPDATE: {
            code: 'POST_PURCHASE_UPDATE',
            name: i18n.t('CONFIGS.POST_PURCHASE_UPDATE'),
        },
        ACCOUNT_UPDATE: {
            code: 'ACCOUNT_UPDATE',
            name: i18n.t('CONFIGS.ACCOUNT_UPDATE'),
        },
    },
    FLOW_CONFIG: {
        CONTENT_TYPE: {
            X_WWW_FORM_URLENCODED: {
                code: 'x-www-form-urlencoded',
                name: 'x-www-form-urlencoded',
            },
            FORM_DATA: {
                code: 'multipart/form-data',
                name: 'multipart/form-data',
            },
            JSON: {
                code: 'application/json',
                name: 'application/json',
            },
        },
        METHODL: {
            POST: {
                name: 'POST',
                code: 'POST',
            },
            GET: {
                name: 'GET',
                code: 'GET',
            },
        },
        REF_PREVIEW: 'UorUJHXnE4',
        ZALO_FIELD: {
            FIRST_NAME: {
                code: 'FIRST_NAME',
                name: i18n.t('SEGMENTS.FIRST_NAME'),
                data_type: 'FIRST_NAME',
            },
            LAST_NAME: {
                code: 'LAST_NAME',
                name: i18n.t('SEGMENTS.LAST_NAME'),
                data_type: 'LAST_NAME',
            },
            FULL_NAME: {
                code: 'FULL_NAME',
                name: i18n.t('SEGMENTS.FULL_NAME'),
                data_type: 'FULL_NAME',
            },
            EMAIL: {
                code: 'EMAIL',
                name: i18n.t('SEGMENTS.EMAIL'),
                data_type: 'EMAIL',
            },
            PHONE: {
                code: 'PHONE',
                name: i18n.t('SEGMENTS.PHONE'),
                data_type: 'PHONE',
            },
            CREATED_AT: {
                code: 'CREATED_AT',
                name: i18n.t('SEGMENTS.CREATED_AT'),
                data_type: 'CREATED_AT',
            },
            // EXPIRE_TIME: {
            //     code: 'EXPIRE_TIME',
            //     name: i18n.t('SEGMENTS.EXPIRE_TIME'),
            //     data_type: 'EXPIRE_TIME',
            // },
            GENDER: {
                code: 'GENDER',
                name: i18n.t('SEGMENTS.GENDER'),
                data_type: 'GENDER',
            },
            LOCALE: {
                code: 'LOCALE',
                name: i18n.t('SEGMENTS.LOCALE'),
                data_type: 'LOCALE',
            },
            TIMEZONE: {
                code: 'TIME_ZONE',
                name: i18n.t('SEGMENTS.TIMEZONE'),
                data_type: 'TIME_ZONE',
            },
        },
        USER_INPUT: {
            TYPE_REPLIES: {
                TEXT: {
                    code: 'TEXT',
                    name: i18n.t('CONFIGS.TEXT'),
                },
                NUMBER: {
                    code: 'NUMBER',
                    name: i18n.t('CONFIGS.NUMBER'),
                },
                EMAIL: {
                    code: 'EMAIL',
                    name: i18n.t('CONFIGS.EMAIL'),
                },
                PHONE: {
                    code: 'PHONE',
                    name: i18n.t('CONFIGS.NUMBER_PHONE'),
                },
            },
        },
        CONFIG_TYPE: {
            MESSAGE: 'MESSAGE',
            SMS: 'SMS',
            EMAIL: 'EMAIL',
            START_FLOW: 'START_FLOW',
            CONDITION: 'CONDITION',
            ZALO: 'ZALO',
            ZALO_CARE: 'ZALO_CARE',
            SMART_DELAY: 'SMART_DELAY',
            PERFORM_ACTIONS: 'PERFORM_ACTIONS',
            AI_CALL: 'AI_CALL',
            INSTAGRAM: 'INSTAGRAM',
        },
        MESSAGE_TYPE: {
            TEXT: 'TEXT',
            QUICK_REPLY: 'QUICK_REPLY',
            IMAGE: 'IMAGE',
            GALLERY: 'GALLERY',
            VIDEO: 'VIDEO',
            FILE: 'FILE',
            DELAY: 'DELAY',
            HEADLINE: 'HEADLINE',
            BUTTON: 'BUTTON',
            AUDIO: 'AUDIO',
            DYNAMIC: 'DYNAMIC',
            USER_INPUT: 'USER_INPUT',
            START_FLOW: 'START_FLOW',
            CONDITION: 'CONDITION',
            NEXT_STEP: 'NEXT_STEP',
            SMART_DELAY: 'SMART_DELAY',
            DURATION: 'DURATION',
            DATE: 'DATE',
            PERFORM_ACTIONS: 'PERFORM_ACTIONS',
            OPEN_EMAIL: 'OPEN_EMAIL',
            CLICK_LINK: 'CLICK_LINK',
            SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
            SEND_MESSAGE_FAIL: 'SEND_MESSAGE_FAIL',
            LIST_ZALO: 'LIST_ZALO',
            ANSWER_PHONE: 'ANSWER_PHONE',
            PHONE_BUSY: 'PHONE_BUSY',
            NOT_PICK_UP: 'NOT_PICK_UP',
            AI_CALL: 'AI_CALL',
        },
        WEBVIEW_SIZE: {
            COMPACT: {
                code: 'COMPACT',
                name: i18n.t('CONFIGS.WEBVIEW_SIZE_COMPACT'),
            },
            TALL: {
                code: 'TALL',
                name: i18n.t('CONFIGS.WEBVIEW_SIZE_TALL'),
            },
            FULL: {
                code: 'FULL',
                name: i18n.t('CONFIGS.WEBVIEW_SIZE_FULL'),
            },
        },
        BUTTON_TYPE: {
            MESSAGE: 'MESSAGE',
            REDIRECT: 'REDIRECT',
            OPEN_URL: 'URL',
            OPEN_SMS: 'OPEN_SMS',
            PHONE_CALL: 'PHONE',
            SMS: 'SMS',
            EMAIL: 'EMAIL',
            START_FLOW: 'START_FLOW',
            DEFAULT: 'DEFAULT',
            CONDITION: 'CONDITION',
            SKIP: 'SKIP',
            ZALO: 'ZALO',
            ZALO_CARE: 'ZALO_CARE',
            SMART_DELAY: 'SMART_DELAY',
            PERFORM_ACTIONS: 'PERFORM_ACTIONS',
            OPEN_EMAIL: 'OPEN_EMAIL',
            CLICK_LINK: 'CLICK_LINK',
            AI_CALL: 'AI_CALL',
            INSTAGRAM: 'INSTAGRAM',

        },
        MESSENGER_TAG: {
            CONFIRMED_EVENT_UPDATE: {
                code: 'CONFIRMED_EVENT_UPDATE',
                name: i18n.t('CONFIGS.CONFIRMED_EVENT_UPDATE'),
            },
            POST_PURCHASE_UPDATE: {
                code: 'POST_PURCHASE_UPDATE',
                name: i18n.t('CONFIGS.POST_PURCHASE_UPDATE'),
            },
            ACCOUNT_UPDATE: {
                code: 'ACCOUNT_UPDATE',
                name: i18n.t('CONFIGS.ACCOUNT_UPDATE'),
            },
        },
        TIMER_UNIT: {
            SECOND: {
                code: 'SECOND',
                name: i18n.t('CONFIGS.SECOND'),
            },
            MINUTE: {
                code: 'MINUTE',
                name: i18n.t('CONFIGS.MINUTE'),
            },
            HOUR: {
                code: 'HOUR',
                name: i18n.t('CONFIGS.HOUR'),
            },
            DAY: {
                code: 'DAY',
                name: i18n.t('CONFIGS.DAY'),
            },
        },
        LEAD_SCORE: {
            ADD: {
                name: 'Tăng',
                code: 'ADD',
            },
            REDUCE: {
                name: 'Giảm',
                code: 'REDUCE',
            },
        },
        RETRY: {
            ONE: {
                code: 1,
                name: i18n.t('RETRY.ONE'),
            },
            TWO: {
                code: 2,
                name: i18n.t('RETRY.TWO'),
            },
            THREE: {
                code: 3,
                name: i18n.t('RETRY.THREE'),
            },
            FOUR: {
                code: 4,
                name: i18n.t('RETRY.FOUR'),
            },
            FIVE: {
                code: 5,
                name: i18n.t('RETRY.FIVE'),
            },
        },
    },
    EVENTS: {
        PAGE_VIEW: {
            code: 'pageView',
            name: i18n.t('FUNNELS.EVENT_PAGE_VIEW'),
        },
        CLICK: {
            code: 'click',
            name: i18n.t('FUNNELS.EVENT_CLICK'),
        },
        SUBMIT: {
            code: 'submit',
            name: i18n.t('FUNNELS.EVENT_SUBMIT'),
        },
        SCROLL_25: {
            code: 'scroll_25',
            name: i18n.t('FUNNELS.EVENT_SCROLL_25'),
        },
        SCROLL_50: {
            code: 'scroll_50',
            name: i18n.t('FUNNELS.EVENT_SCROLL_50'),
        },
        SCROLL_75: {
            code: 'scroll_75',
            name: i18n.t('FUNNELS.EVENT_SCROLL_75'),
        },
        SCROLL_100: {
            code: 'scroll_100',
            name: i18n.t('FUNNELS.EVENT_SCROLL_100'),
        },
        SCROLL: {
            key: 'scroll_',
        },
        TIME_ON_PAGE: {
            key: 'timeOnPage_',
            name: i18n.t('FUNNELS.EVENT_TIME_ON_PAGE'),
        },
    },
    SRC_IMAGE: 'https://w.ladicdn.com/s600x600',
    TYPE_IMAGE: {
        SUBMIT_IMAGE: 'SUBMIT_IMAGE',
        SUBMIT_GALLERY: 'SUBMIT_GALLERY',
        CLOSE: 'CLOSE',
        CLOSE_GALLERY: 'CLOSE_GALLERY',
        SAVE_URL: 'SAVE_URL',
        IMAGE: 'IMAGE',
        GALLERY: 'GALLERY',
        CHANGE_TITLE: 'CHANGE_TITLE',
        CHANGE_IMAGE: 'CHANGE_IMAGE',
        LIST: 'LIST',
        CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION',
        ADD_URL_GALLERY: 'ADD_URL_GALLERY',
        VIDEO: 'VIDEO',
        FILE: 'FILE',
        CHANGE_TEXT_LIST: 'CHANGE_TEXT_LIST',
        TYPING: 'TYPING',
        AUDIO: 'AUDIO',
    },
    UPLOAD_FILE: {
        MAX_SIZE: {
            VIDEO: 24117248,
            IMAGE: 5242880,
            DOCUMENT: 24117248,
        },

        NOT_ACCEPT: ['bat', 'exe'],
        IMAGE: ['jpg', 'png', 'jpeg', 'gif', 'svg'],
        VIDEO: ['mp4', 'avi', 'wmv', '3gp', 'flv'],
        FILE: ['csv', 'txt', 'pdf', 'doc', 'xlsx'],
        AUDIO: ['mp3'],
        TYPE: {
            IMAGE: 'IMAGE',
            VIDEO: 'VIDEO',
            AUDIO: 'AUDIO',
            DOCUMENT: 'DOCUMENT',
        },
    },
    INTEGRATION: {
        TYPE: {
            DEFAULT: 'DEFAULT',
            SMTP: 'SMTP',
            EMAIL: 'EMAIL',
            LADIMAIL: 'LADIMAIL',
            ESMS: 'ESMS',
            FB_PAGE: 'FB_PAGE',
            ZALO: 'ZALO',
            LADILINK: 'LADILINK',
            HARAVAN: 'HARAVAN',
            SAPO: 'SAPO',
            SHOPIFY: 'SHOPIFY',
            WORDPRESS: 'WORDPRESS',
            KIOTVIET: 'KIOTVIET',
            GETRESPONSE: 'GETRESPONSE',
            MAIL_CHIMP: 'MAIL_CHIMP',
            GOOGLE_SHEET: 'GOOGLE_SHEET',
            INFUSION_SOFT: 'INFUSION_SOFT',
            HUBSPOT: 'HUBSPOT',
            ACTIVE_CAMPAIGN: 'ACTIVE_CAMPAIGN',
            CONVERTKIT: 'CONVERTKIT',
            NHANH: 'NHANH',
            AI_CALL: 'AI_CALL',
            INSTAGRAM: 'INSTAGRAM',
        },
    },
    SUBSCRIBERS: {
        TYPE: {
            SUBSCRIBERS: 'SUBSCRIBERS',
            SUBSCRIBERS_REAL: 'SUBSCRIBERS_REAL',
            UNSUBSCRIBE: 'UNSUBSCRIBE',
            TOTAL_SUBSCRIBERS: 'TOTAL_SUBSCRIBERS',
        },
    },
    GROWTH_TOOL: {
        STATUS: {
            ALL: {
                code: 'all',
                name: i18n.t('VOUCHERS.SELECT_ALL'),
            },
            STOP: {
                code: false,
                name: i18n.t('GROWTH_TOOL.STOP'),
            },
            RUN: {
                code: true,
                name: i18n.t('GROWTH_TOOL.RUN'),
            },
        },
        ZALO_TYPE: {
            WIDGET_ZALO: {
                code: 'WIDGET_ZALO',
                name: 'Quan tâm Zalo OA từ Widget',
            },
            BUTTON_ZALO: {
                code: 'BUTTON_ZALO',
                name: 'Quan tâm Zalo OA từ Button',
            },
            PROFILE: {
                code: 'PROFILE',
                name: 'Quan tâm Zalo OA từ Profile',
            },
        },
        COVER_IMAGE: {
            YES: {
                code: 'yes',
                name: i18n.t('GROWTH_TOOL.COVER_IMAGE.YES'),
            },
            NO: {
                code: 'no',
                name: i18n.t('GROWTH_TOOL.COVER_IMAGE.NO'),
            },
        },
        MULTI_POST: {
            A_SPECIFIC_POST: {
                code: false,
                name: i18n.t('GROWTH_TOOL.A_SPECIFIC_POST'),
            },
            ALL_POSTS: {
                code: true,
                name: i18n.t('GROWTH_TOOL.ALL_POSTS'),
            },
        },
        TOTAL_TYPE: {
            INSTAGRAM_MESSENGER_KEYWORD: {
                code: 'INSTAGRAM_MESSENGER_KEYWORD',
                name: i18n.t('GROWTH_TOOL.INSTAGRAM_MESSENGER_KEYWORD'),
            },
            INSTAGRAM_COMMENTS_KEYWORD: {
                code: 'INSTAGRAM_COMMENTS_KEYWORD',
                name: i18n.t('GROWTH_TOOL.INSTAGRAM_COMMENTS_KEYWORD'),
            },
            HAPPY_BIRTHDAY: {
                code: 'HAPPY_BIRTHDAY',
                name: i18n.t('GROWTH_TOOL.HAPPY_BIRTHDAY'),
            },
            BUTTON: {
                code: 'BUTTON',
                name: 'Subscribe từ Widget Chat',
            },
            REF: {
                code: 'REF',
                name: i18n.t('GROWTH_TOOL.REF'),
            },
            MESSENGER_KEYWORD: {
                code: 'MESSENGER_KEYWORD',
                name: i18n.t('GROWTH_TOOL.MESSENGER_KEYWORD'),
            },
            FACEBOOK_COMMENTS: {
                code: 'FACEBOOK_COMMENTS',
                name: i18n.t('GROWTH_TOOL.FACEBOOK_COMMENTS'),
            },
            FACEBOOK_ADS_JSON: {
                code: 'FACEBOOK_ADS_JSON',
                name: 'Ads JSON',
            },
            FACEBOOK_NEW_LEAD_ADS_FORM: {
                code: 'FACEBOOK_NEW_LEAD_ADS_FORM',
                name: 'Khi có Lead mới từ FB Lead Ads Form',
            },
            ZALO_FOLLOW: {
                code: 'ZALO_FOLLOW',
                name: 'Quan tâm Zalo OA từ (Profile, Button, Widget)',
            },
            WIDGET_ZALO: {
                code: 'WIDGET_ZALO',
                name: 'Quan tâm Zalo OA từ Widget',
            },
            BUTTON_ZALO: {
                code: 'BUTTON_ZALO',
                name: 'Quan tâm Zalo OA từ Button',
            },
            ZALO_KEYWORD: {
                code: 'ZALO_KEYWORD',
                name: i18n.t('GROWTH_TOOL.ZALO_KEYWORD'),
            },
            QR_CODE: {
                code: 'QR_CODE',
                name: 'Khi quét QR Code',
            },
            NEW_SUBSCRIBER: {
                code: 'NEW_SUBSCRIBER',
                name: i18n.t('GROWTH_TOOL.NEW_SUBSCRIBER'),
            },
            TAG_APPLY: {
                code: 'TAG_APPLY',
                name: i18n.t('GROWTH_TOOL.TAG_APPLY'),
            },
            TAG_REMOVE: {
                code: 'TAG_REMOVE',
                name: i18n.t('GROWTH_TOOL.TAG_REMOVE'),
            },
            SUBSCRIBED_TO_SEQUENCE: {
                code: 'SUBSCRIBED_TO_SEQUENCE',
                name: i18n.t('GROWTH_TOOL.SUBSCRIBED_TO_SEQUENCE'),
            },
            UNSUBSCRIBED_TO_SEQUENCE: {
                code: 'UNSUBSCRIBED_TO_SEQUENCE',
                name: i18n.t('GROWTH_TOOL.UNSUBSCRIBED_TO_SEQUENCE'),
            },
            CUSTOM_FIELD_VALUE_CHANGED: {
                code: 'CUSTOM_FIELD_VALUE_CHANGED',
                name: i18n.t('GROWTH_TOOL.CUSTOM_FIELD_VALUE_CHANGED'),
            },
            SYSTEM_FIELD_VALUE_CHANGED: {
                code: 'SYSTEM_FIELD_VALUE_CHANGED',
                name: i18n.t('GROWTH_TOOL.SYSTEM_FIELD_VALUE_CHANGED'),
            },
            SCORE_CHANGED: {
                code: 'SCORE_CHANGED',
                name: i18n.t('GROWTH_TOOL.SCORE_CHANGED'),
                isShowCustomer: false,
            },
            COMPLETED_SEQUENCE: {
                code: 'COMPLETED_SEQUENCE',
                name: i18n.t('GROWTH_TOOL.COMPLETED_SEQUENCE'),
                isShowCustomer: false,
            },
            SHOPIFY_CUSTOMER_CREATED: {
                code: 'SHOPIFY_CUSTOMER_CREATED',
                name: 'Khi một khách hàng mới được tạo trên',
            },
            SHOPIFY_CUSTOMER_UPDATED: {
                code: 'SHOPIFY_CUSTOMER_UPDATED',
                name: 'Khi một khách hàng được cập nhật',
            },
            SHOPIFY_ORDER_CREATED_ANY_STATUS: {
                code: 'SHOPIFY_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
            },
            SHOPIFY_ORDER_CREATED_OPEN_STATUS: {
                code: 'SHOPIFY_ORDER_CREATED_OPEN_STATUS',
                name: 'Khi một đơn hàng mới được tạo mới ỏ trạng thái mở',
            },
            SHOPIFY_ORDER_UPDATED: {
                code: 'SHOPIFY_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            SHOPIFY_PAID_ORDER: {
                code: 'SHOPIFY_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            SHOPIFY_ORDER_CANCELLED: {
                code: 'SHOPIFY_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            SHOPIFY_PRODUCT_CREATED: {
                code: 'SHOPIFY_PRODUCT_CREATED',
                name: 'Khi một sản phẩm được tạo mới',
            },
            SHOPIFY_ABANDONED_CART: {
                code: 'SHOPIFY_ABANDONED_CART',
                name: 'Khi ai đó bỏ giỏ hàng',
            },
            HARAVAN_CUSTOMER_CREATED: {
                code: 'HARAVAN_CUSTOMER_CREATED',
                name: 'Khi một khách hàng mới được tạo',
            },
            HARAVAN_CUSTOMER_UPDATED: {
                code: 'HARAVAN_CUSTOMER_UPDATED',
                name: 'Khi một khách hàng được cập nhật',
            },
            HARAVAN_ORDER_CREATED_ANY_STATUS: {
                code: 'HARAVAN_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
            },
            HARAVAN_ORDER_CREATED_OPEN_STATUS: {
                code: 'HARAVAN_ORDER_CREATED_OPEN_STATUS',
                name: 'Khi một đơn hàng mới được tạo mới ỏ trạng thái mở',
            },
            HARAVAN_ORDER_UPDATED: {
                code: 'HARAVAN_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            HARAVAN_PAID_ORDER: {
                code: 'HARAVAN_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            HARAVAN_ORDER_CANCELLED: {
                code: 'HARAVAN_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            HARAVAN_PRODUCT_CREATED: {
                code: 'HARAVAN_PRODUCT_CREATED',
                name: 'Khi một sản phẩm được tạo mới',
            },
            HARAVAN_ABANDONED_CART: {
                code: 'HARAVAN_ABANDONED_CART',
                name: 'Khi ai đó bỏ giỏ hàng',
            },
            LADISALES_CUSTOMER_CREATED: {
                code: 'LADISALES_CUSTOMER_CREATED',
                name: 'Khi một khách hàng mới được tạo',
            },
            LADISALES_CUSTOMER_UPDATED: {
                code: 'LADISALES_CUSTOMER_UPDATED',
                name: 'Khi một khách hàng được cập nhật',
            },
            LADISALES_ORDER_CREATED_ANY_STATUS: {
                code: 'LADISALES_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
            },
            LADISALES_ORDER_CREATED_OPEN_STATUS: {
                code: 'LADISALES_ORDER_CREATED_OPEN_STATUS',
                name: 'Khi một đơn hàng mới được tạo mới ỏ trạng thái mở',
            },
            LADISALES_ORDER_UPDATED: {
                code: 'LADISALES_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            LADISALES_PAID_ORDER: {
                code: 'LADISALES_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            LADISALES_ORDER_CANCELLED: {
                code: 'LADISALES_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            LADISALES_PRODUCT_CREATED: {
                code: 'LADISALES_PRODUCT_CREATED',
                name: 'Khi một sản phẩm được tạo mới',
            },
            LADISALES_ABANDONED_CART: {
                code: 'LADISALES_ABANDONED_CART',
                name: 'Khi ai đó bỏ giỏ hàng',
            },
            WOOCOMMERCE_CUSTOMER_CREATED: {
                code: 'WOOCOMMERCE_CUSTOMER_CREATED',
                name: 'Khi một khách hàng mới được tạo',
            },
            WOOCOMMERCE_CUSTOMER_UPDATED: {
                code: 'WOOCOMMERCE_CUSTOMER_UPDATED',
                name: 'Khi một khách hàng được cập nhật',
            },
            WOOCOMMERCE_ORDER_CREATED_ANY_STATUS: {
                code: 'WOOCOMMERCE_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
            },
            WOOCOMMERCE_ORDER_CREATED_OPEN_STATUS: {
                code: 'WOOCOMMERCE_ORDER_CREATED_OPEN_STATUS',
                name: 'Khi một đơn hàng mới được tạo mới ỏ trạng thái mở',
            },
            WOOCOMMERCE_ORDER_UPDATED: {
                code: 'WOOCOMMERCE_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            WOOCOMMERCE_PAID_ORDER: {
                code: 'WOOCOMMERCE_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            WOOCOMMERCE_ORDER_CANCELLED: {
                code: 'WOOCOMMERCE_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            WOOCOMMERCE_PRODUCT_CREATED: {
                code: 'WOOCOMMERCE_PRODUCT_CREATED',
                name: 'Khi một sản phẩm được tạo mới',
            },
            WOOCOMMERCE_ABANDONED_CART: {
                code: 'WOOCOMMERCE_ABANDONED_CART',
                name: 'Khi ai đó bỏ giỏ hàng',
            },
            KIOTVIET_ORDER_CREATED_ANY_STATUS: {
                code: 'KIOTVIET_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                isShowCustomer: false,
            },
            KIOTVIET_ORDER_UPDATED: {
                code: 'KIOTVIET_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            KIOTVIET_PAID_ORDER: {
                code: 'KIOTVIET_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            KIOTVIET_ORDER_CANCELLED: {
                code: 'KIOTVIET_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            KIOTVIET_PRODUCT_CREATED: {
                code: 'KIOTVIET_PRODUCT_CREATED',
                name: 'Khi một sản phẩm được tạo mới',
            },
            KIOTVIET_CUSTOMER_UPDATED: {
                code: 'KIOTVIET_CUSTOMER_UPDATED',
                name: 'Khi một khách hàng được cập nhật',
            },
            SAPO_ORDER_CREATED_ANY_STATUS: {
                code: 'SAPO_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
            },
            SAPO_ORDER_UPDATED: {
                code: 'SAPO_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            SAPO_PAID_ORDER: {
                code: 'SAPO_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            SAPO_ORDER_CANCELLED: {
                code: 'SAPO_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            SAPO_ABANDONED_CART: {
                code: 'SAPO_ABANDONED_CART',
                name: 'Khi ai đó bỏ giỏ hàng',
            },
            NHANH_ORDER_CREATED_ANY_STATUS: {
                code: 'NHANH_ORDER_CREATED_ANY_STATUS',
                name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
            },
            NHANH_ORDER_UPDATED: {
                code: 'NHANH_ORDER_UPDATED',
                name: 'Khi một đơn hàng được cập nhật',
            },
            NHANH_PAID_ORDER: {
                code: 'NHANH_PAID_ORDER',
                name: 'Khi một đơn hàng được thanh toán thành công',
            },
            NHANH_ORDER_CANCELLED: {
                code: 'NHANH_ORDER_CANCELLED',
                name: 'Khi một đơn hàng bị hủy',
            },
            CATCH_HOOK: {
                code: 'CATCH_HOOK',
                name: i18n.t('GROWTH_TOOL.CATCH_HOOK'),
            },
            LADICHAT_CREATE_DEAL: {
                code: 'LADICHAT_CREATE_DEAL',
                name: 'Khi một Deal mới được tạo',
            },
            LADICHAT_CHANGE_STATUS: {
                code: 'LADICHAT_CHANGE_STATUS',
                name: 'Khi một Deal thay đổi trạng thái',
            },
            LADICHAT_CHANGE_PIPELINE: {
                code: 'LADICHAT_CHANGE_PIPELINE',
                name: 'Khi một Deal thay đổi Pipeline',
            },
            LADICHAT_LEAD_NO_REPLY: {
                code: 'LADICHAT_LEAD_NO_REPLY',
                name: 'Khi Lead không phản hồi',
            },
            LADICHAT_CUSTOMER_NO_REPLY: {
                code: 'LADICHAT_CUSTOMER_NO_REPLY',
                name: 'Khi khách hàng không phản hồi',
            },
            LADICHAT_ADD_CUSTOMER_NOTE: {
                code: 'LADICHAT_ADD_CUSTOMER_NOTE',
                name: 'Khi add Note vào Khách hàng',
            },
            LADICHAT_CUSTOMER_CREATE: {
                code: 'LADICHAT_CUSTOMER_CREATE',
                name: 'Khi tạo mới khách hàng',
            },
            LADICHAT_CUSTOMER_UPDATE: {
                code: 'LADICHAT_CUSTOMER_UPDATE',
                name: 'Khi cập nhật khách hàng',
            },
            LADICHAT_CUSTOMER_DELETE: {
                code: 'LADICHAT_CUSTOMER_DELETE',
                name: 'Khi xóa khách hàng',
            },
        },

        TYPE: [
            {
                isShowCustomer: true,
                OTHER: [
                    {
                        NEW_SUBSCRIBER: {
                            code: 'NEW_SUBSCRIBER',
                            name: i18n.t('GROWTH_TOOL.NEW_SUBSCRIBER'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        TAG_APPLY: {
                            code: 'TAG_APPLY',
                            name: i18n.t('GROWTH_TOOL.TAG_APPLY'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        TAG_REMOVE: {
                            code: 'TAG_REMOVE',
                            name: i18n.t('GROWTH_TOOL.TAG_REMOVE'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        SUBSCRIBED_TO_SEQUENCE: {
                            code: 'SUBSCRIBED_TO_SEQUENCE',
                            name: i18n.t('GROWTH_TOOL.SUBSCRIBED_TO_SEQUENCE'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        UNSUBSCRIBED_TO_SEQUENCE: {
                            code: 'UNSUBSCRIBED_TO_SEQUENCE',
                            name: i18n.t('GROWTH_TOOL.UNSUBSCRIBED_TO_SEQUENCE'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        CUSTOM_FIELD_VALUE_CHANGED: {
                            code: 'CUSTOM_FIELD_VALUE_CHANGED',
                            name: i18n.t('GROWTH_TOOL.CUSTOM_FIELD_VALUE_CHANGED'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        SYSTEM_FIELD_VALUE_CHANGED: {
                            code: 'SYSTEM_FIELD_VALUE_CHANGED',
                            name: i18n.t('GROWTH_TOOL.SYSTEM_FIELD_VALUE_CHANGED'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        HAPPY_BIRTHDAY: {
                            code: 'HAPPY_BIRTHDAY',
                            name: i18n.t('GROWTH_TOOL.HAPPY_BIRTHDAY'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        CATCH_HOOK: {
                            code: 'CATCH_HOOK',
                            name: i18n.t('GROWTH_TOOL.CATCH_HOOK'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        SCORE_CHANGED: {
                            code: 'SCORE_CHANGED',
                            name: i18n.t('GROWTH_TOOL.SCORE_CHANGED'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        COMPLETED_SEQUENCE: {
                            code: 'COMPLETED_SEQUENCE',
                            name: i18n.t('GROWTH_TOOL.COMPLETED_SEQUENCE'),
                            isShowCustomer: true,
                        },
                    },
                ],
            },
            {
                isShowCustomer: true,
                FACEBOOK: [
                    {
                        BUTTON: {
                            code: 'BUTTON',
                            name: 'Subscribe từ Widget Chat',
                            isShowCustomer: true,
                        },
                    },
                    {
                        REF: {
                            code: 'REF',
                            name: i18n.t('GROWTH_TOOL.REF'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        MESSENGER_KEYWORD: {
                            code: 'MESSENGER_KEYWORD',
                            name: i18n.t('GROWTH_TOOL.MESSENGER_KEYWORD'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        FACEBOOK_COMMENTS: {
                            code: 'FACEBOOK_COMMENTS',
                            name: i18n.t('GROWTH_TOOL.FACEBOOK_COMMENTS'),
                            isShowCustomer: true,
                        },
                    },
                    {
                        FACEBOOK_ADS_JSON: {
                            code: 'FACEBOOK_ADS_JSON',
                            name: 'Ads JSON',
                            isShowCustomer: true,
                        },
                    },
                    {
                        FACEBOOK_NEW_LEAD_ADS_FORM: {
                            code: 'FACEBOOK_NEW_LEAD_ADS_FORM',
                            name: 'Khi có Lead mới từ FB Lead Ads Form',
                            isShowCustomer: true,
                        },
                    },
                    {
                        QR_CODE: {
                            code: 'QR_CODE',
                            name: 'Khi quét QR Code',
                            isShowCustomer: true,
                        },
                    },
                ],
            },
            {
                isShowCustomer: false,
                INSTAGRAM: [
                    {
                        INSTAGRAM_MESSENGER_KEYWORD: {
                            code: 'INSTAGRAM_MESSENGER_KEYWORD',
                            name: i18n.t('GROWTH_TOOL.INSTAGRAM_MESSENGER_KEYWORD'),
                            isShowCustomer: false,
                        },
                    },
                    {
                        INSTAGRAM_COMMENTS_KEYWORD: {
                            code: 'INSTAGRAM_COMMENTS_KEYWORD',
                            name: i18n.t('GROWTH_TOOL.INSTAGRAM_COMMENTS_KEYWORD'),
                            isShowCustomer: false,
                        },
                    },
                ],
            },
            {
                isShowCustomer: true,
                ZALO: [
                    {
                        ZALO_FOLLOW: {
                            code: 'ZALO_FOLLOW',
                            name: 'Quan tâm Zalo OA từ (Profile, Button, Widget)',
                            isShowCustomer: true,
                        },
                    },
                    {
                        ZALO_KEYWORD: {
                            code: 'ZALO_KEYWORD',
                            name: i18n.t('GROWTH_TOOL.ZALO_KEYWORD'),
                            isShowCustomer: true,
                        },
                    },
                ],
            }, {
                isShowCustomer: true,
                LADISALES: [
                    {
                        LADISALES_ORDER_CREATED_ANY_STATUS: {
                            code: 'LADISALES_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    }, {
                        LADISALES_ORDER_UPDATED: {
                            code: 'LADISALES_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    }, {
                        LADISALES_PAID_ORDER: {
                            code: 'LADISALES_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    }, {
                        LADISALES_ORDER_CANCELLED: {
                            code: 'LADISALES_ORDER_CANCELLED',
                            name: 'Khi một đơn hàng bị hủy',
                            isShowCustomer: true,
                        },
                    }, {
                        LADISALES_ABANDONED_CART: {
                            code: 'LADISALES_ABANDONED_CART',
                            name: 'Khi ai đó bỏ giỏ hàng',
                            isShowCustomer: true,
                        },
                    },
                ],
            }, {
                isShowCustomer: true,
                SHOPIFY: [
                    {
                        SHOPIFY_ORDER_CREATED_ANY_STATUS: {
                            code: 'SHOPIFY_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    },
                    {
                        SHOPIFY_ORDER_UPDATED: {
                            code: 'SHOPIFY_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    }, {
                        SHOPIFY_PAID_ORDER: {
                            code: 'SHOPIFY_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    }, {
                        SHOPIFY_ORDER_CANCELLED: {
                            code: 'SHOPIFY_ORDER_CANCELLED',
                            name: 'Khi một đơn hàng bị hủy',
                            isShowCustomer: true,
                        },
                    }, {

                        SHOPIFY_ABANDONED_CART: {
                            code: 'SHOPIFY_ABANDONED_CART',
                            name: 'Khi ai đó bỏ giỏ hàng',
                            isShowCustomer: true,
                        },
                    },
                ],
            },
            {
                isShowCustomer: true,
                HARAVAN: [
                    {
                        HARAVAN_ORDER_CREATED_ANY_STATUS: {
                            code: 'HARAVAN_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    },
                    {
                        HARAVAN_ORDER_UPDATED: {
                            code: 'HARAVAN_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    },
                    {
                        HARAVAN_PAID_ORDER: {
                            code: 'HARAVAN_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    },
                    {
                        HARAVAN_ORDER_CANCELLED: {
                            code: 'HARAVAN_ORDER_CANCELLED',
                            name: 'Khi một đơn hàng bị hủy',
                            isShowCustomer: true,
                        },
                    },
                    {
                        HARAVAN_ABANDONED_CART: {
                            code: 'HARAVAN_ABANDONED_CART',
                            name: 'Khi ai đó bỏ giỏ hàng',
                            isShowCustomer: true,
                        },
                    },
                ],
            },
            {
                isShowCustomer: true,
                SAPO: [
                    {
                        SAPO_ORDER_CREATED_ANY_STATUS: {
                            code: 'SAPO_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    }, {
                        SAPO_ORDER_UPDATED: {
                            code: 'SAPO_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    }, {
                        SAPO_PAID_ORDER: {
                            code: 'SAPO_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    }, {
                        SAPO_ORDER_CANCELLED: {
                            code: 'SAPO_ORDER_CANCELLED',
                            name: 'Khi một đơn hàng bị hủy',
                            isShowCustomer: true,
                        },
                    },
                ],
            }, {
                isShowCustomer: true,
                WOOCOMMERCE: [
                    {
                        WOOCOMMERCE_ORDER_CREATED_ANY_STATUS: {
                            code: 'WOOCOMMERCE_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    }, {
                        WOOCOMMERCE_ORDER_UPDATED: {
                            code: 'WOOCOMMERCE_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    }, {
                        WOOCOMMERCE_PAID_ORDER: {
                            code: 'WOOCOMMERCE_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    }, {
                        WOOCOMMERCE_ORDER_CANCELLED: {
                            code: 'WOOCOMMERCE_ORDER_CANCELLED',
                            name: 'Khi một đơn hàng bị hủy',
                            isShowCustomer: true,
                        },
                    },
                ],
            }, {
                isShowCustomer: true,
                NHANH: [
                    {
                        NHANH_ORDER_CREATED_ANY_STATUS: {
                            code: 'NHANH_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    },
                    {
                        NHANH_ORDER_UPDATED: {
                            code: 'NHANH_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    }, {
                        NHANH_PAID_ORDER: {
                            code: 'NHANH_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    }, {
                        NHANH_ORDER_CANCELLED: {
                            code: 'NHANH_ORDER_CANCELLED',
                            name: 'Khi một đơn hàng bị hủy',
                            isShowCustomer: true,
                        },
                    },
                ],
            }, {
                isShowCustomer: true,
                KIOTVIET: [
                    {
                        KIOTVIET_ORDER_CREATED_ANY_STATUS: {
                            code: 'KIOTVIET_ORDER_CREATED_ANY_STATUS',
                            name: 'Khi một đơn hàng mới được tạo ở bất kỳ trạng thái nào',
                            isShowCustomer: true,
                        },
                    }, {
                        KIOTVIET_ORDER_UPDATED: {
                            code: 'KIOTVIET_ORDER_UPDATED',
                            name: 'Khi một đơn hàng được cập nhật',
                            isShowCustomer: true,
                        },
                    }, {
                        KIOTVIET_PAID_ORDER: {
                            code: 'KIOTVIET_PAID_ORDER',
                            name: 'Khi một đơn hàng được thanh toán thành công',
                            isShowCustomer: true,
                        },
                    },
                ],
            },
            {
                isShowCustomer: false,
                LADICHAT: [
                    {
                        LADICHAT_CREATE_DEAL: {
                            code: 'LADICHAT_CREATE_DEAL',
                            name: 'Khi một Deal mới được tạo',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_CHANGE_STATUS: {
                            code: 'LADICHAT_CHANGE_STATUS',
                            name: 'Khi một Deal thay đổi trạng thái',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_CHANGE_PIPELINE: {
                            code: 'LADICHAT_CHANGE_PIPELINE',
                            name: 'Khi một Deal thay đổi Pipeline',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_LEAD_NO_REPLY: {
                            code: 'LADICHAT_LEAD_NO_REPLY',
                            name: 'Khi Lead không phản hồi',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_CUSTOMER_NO_REPLY: {
                            code: 'LADICHAT_CUSTOMER_NO_REPLY',
                            name: 'Khi khách hàng không phản hồi',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_ADD_CUSTOMER_NOTE: {
                            code: 'LADICHAT_ADD_CUSTOMER_NOTE',
                            name: 'Khi add Note vào Khách hàng',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_CUSTOMER_CREATE: {
                            code: 'LADICHAT_CUSTOMER_CREATE',
                            name: 'Khi tạo mới khách hàng',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_CUSTOMER_UPDATE: {
                            code: 'LADICHAT_CUSTOMER_UPDATE',
                            name: 'Khi cập nhật khách hàng',
                            isShowCustomer: false,
                        },
                    },
                    {
                        LADICHAT_CUSTOMER_DELETE: {
                            code: 'LADICHAT_CUSTOMER_DELETE',
                            name: 'Khi xóa khách hàng',
                            isShowCustomer: false,
                        },
                    },
                ],
            },
        ],
    },
    BROADCAST: {
        TYPE: {
            MESSENGER: {
                code: 'MESSENGER',
                name: i18n.t('BROADCAST.MESSENGER'),
            },
            EMAIL: {
                code: 'EMAIL',
                name: i18n.t('BROADCAST.EMAIL'),
            },
            SMS: {
                code: 'SMS',
                name: i18n.t('BROADCAST.SMS'),
            },
        },
        STATUS: {
            READY: 'READY',
            DRAFT: 'DRAFT',
            STARTED: 'STARTED',
            FINISHED: 'FINISHED',
            DONE: 'DONE',
            STOP: 'STOP',
            RESUME: 'RESUME',
        },
        BROADCAST_STATUS: {
            DRAFT: {
                code: 'DRAFT',
                name: i18n.t('BROADCAST.STATUS_DRAFT'),
            },
            RUNNING: {
                code: 'RUNNING',
                name: i18n.t('BROADCAST.STATUS_RUNNING'),
            },
            STOP: {
                code: 'STOP',
                name: i18n.t('BROADCAST.STATUS_STOP'),
            },
            DONE: {
                code: 'DONE',
                name: i18n.t('BROADCAST.STATUS_DONE'),
            },
        },
    },
    SEQUENCE: {
        FROM: 'FROM',
        TO: 'TO',
        ADD_MESSAGE: {
            TYPE: {
                MESSAGE: 'MESSAGE',
                ACTION: 'ACTION',
            },
        },
        TIME_IN_DAY: {
            ZERO: {
                code: 0,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.0'),
            },
            ONE: {
                code: 1,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.1'),
            },
            TWO: {
                code: 2,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.2'),
            },
            THREE: {
                code: 3,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.3'),
            },
            FOUR: {
                code: 4,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.4'),
            },
            FIVE: {
                code: 5,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.5'),
            },
            SIX: {
                code: 6,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.6'),
            },
            SEVEN: {
                code: 7,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.7'),
            },
            EIGHT: {
                code: 8,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.8'),
            },
            NINE: {
                code: 9,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.9'),
            },

            TEN: {
                code: 10,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.10'),
            },
            ELEVEN: {
                code: 11,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.11'),
            },
            TWELVE: {
                code: 12,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.12'),
            },
            THIRTEEN: {
                code: 13,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.13'),
            },
            FOURTEEN: {
                code: 14,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.14'),
            },
            FIFTEEN: {
                code: 15,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.15'),
            },
            SIXTEEN: {
                code: 16,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.16'),
            },
            SEVENTEEN: {
                code: 17,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.17'),
            },
            EIGHTEEN: {
                code: 18,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.18'),
            },
            NINETEEN: {
                code: 19,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.19'),
            },
            TWENTY: {
                code: 20,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.20'),
            },
            TWENTY_ONE: {
                code: 21,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.21'),
            },
            TWENTY_TWO: {
                code: 22,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.22'),
            },
            TWENTY_THREE: {
                code: 23,
                name: i18n.t('SEQUENCES.TIME_IN_DAY.23'),
            },
        },
        TIME_SEND_MESSAGE: {
            ANY_TIME: {
                code: 'ANY_TIME',
                name: i18n.t('SEQUENCES.ANY_TIME'),
            },
            SEND_BETWEEN: {
                code: 'SEND_BETWEEN',
                name: i18n.t('SEQUENCES.SEND_BETWEEN'),
            },
        },
        TIMER_UNIT: {
            IMMEDIATELY: {
                code: 'IMMEDIATELY',
                name: i18n.t('SEQUENCES.IMMEDIATELY'),
            },
            MINUTE: {
                code: 'MINUTE',
                name: i18n.t('CONFIGS.MINUTE'),
            },
            HOUR: {
                code: 'HOUR',
                name: i18n.t('CONFIGS.HOUR'),
            },
            DAY: {
                code: 'DAY',
                name: i18n.t('CONFIGS.DAY'),
            },
        },
        DAY_OF_WEEK: {
            MONDAY: {
                code: 1,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.MONDAY'),
            },
            TUESDAY: {
                code: 2,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.TUESDAY'),
            },
            WEDNESDAY: {
                code: 3,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.WEDNESDAY'),
            },
            THURSDAY: {
                code: 4,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.THURSDAY'),
            },
            FRIDAY: {
                code: 5,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.FRIDAY'),
            },
            SATURDAY: {
                code: 6,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.SATURDAY'),
            },
            SUNDAY: {
                code: 0,
                name: i18n.t('SEQUENCES.DAY_OF_WEEK.SUNDAY'),
            },
        },
    },
    CATCH_HOOK_FIELD_DEFAULT: {
        FULL_NAME: {
            code: 'full_name',
            name: 'full_name',
        },
        EMAIL: {
            code: 'email',
            name: 'email',
        },
        PHONE: {
            code: 'phone',
            name: 'phone',
        },
        GENDER: {
            code: 'gender',
            name: 'gender',
        },
        ADDRESS: {
            code: 'address',
            name: 'address',
        },
    },
    CATCH_HOOK_FIELD_GOOGLE_LEAD_ADS: {
        FULL_NAME: {
            code: 'FULL_NAME',
            name: 'FULL_NAME',
        },
        FIRST_NAME: {
            code: 'FIRST_NAME',
            name: 'FIRST_NAME',
        },
        LAST_NAME: {
            code: 'LAST_NAME',
            name: 'LAST_NAME',
        },
        EMAIL: {
            code: 'EMAIL',
            name: 'EMAIL',
        },
        PHONE_NUMBER: {
            code: 'PHONE_NUMBER',
            name: 'PHONE_NUMBER',
        },
        POSTAL_CODE: {
            code: 'POSTAL_CODE',
            name: 'POSTAL_CODE',
        },
        COMPANY_NAME: {
            code: 'COMPANY_NAME',
            name: 'COMPANY_NAME',
        },
        JOB_TITLE: {
            code: 'JOB_TITLE',
            name: 'JOB_TITLE',
        },
        WORK_EMAIL: {
            code: 'WORK_EMAIL',
            name: 'WORK_EMAIL',
        },
        WORK_PHONE: {
            code: 'WORK_PHONE',
            name: 'WORK_PHONE',
        },
        STREET_ADDRESS: {
            code: 'STREET_ADDRESS',
            name: 'STREET_ADDRESS',
        },
        CITY: {
            code: 'CITY',
            name: 'CITY',
        },
        REGION: {
            code: 'REGION',
            name: 'REGION',
        },
        COUNTRY: {
            code: 'COUNTRY',
            name: 'COUNTRY',
        },

        VEHICLE_MODEL: {
            code: 'VEHICLE_MODEL',
            name: 'VEHICLE_MODEL',
        },
        VEHICLE_TYPE: {
            code: 'VEHICLE_TYPE',
            name: 'VEHICLE_TYPE',
        },
        PREFERRED_DEALERSHIP: {
            code: 'PREFERRED_DEALERSHIP',
            name: 'PREFERRED_DEALERSHIP',
        },
        VEHICLE_PURCHASE_TIMELINE: {
            code: 'VEHICLE_PURCHASE_TIMELINE',
            name: 'VEHICLE_PURCHASE_TIMELINE',
        },
        VEHICLE_CONDITION: {
            code: 'VEHICLE_CONDITION',
            name: 'VEHICLE_CONDITION',
        },
        VEHICLE_OWNERSHIP: {
            code: 'VEHICLE_OWNERSHIP',
            name: 'VEHICLE_OWNERSHIP',
        },
        VEHICLE_PAYMENT_TYPE: {
            code: 'VEHICLE_PAYMENT_TYPE',
            name: 'VEHICLE_PAYMENT_TYPE',
        },
        COMPANY_SIZE: {
            code: 'COMPANY_SIZE',
            name: 'COMPANY_SIZE',
        },
        ANNUAL_SALES: {
            code: 'ANNUAL_SALES',
            name: 'ANNUAL_SALES',
        },
        YEARS_IN_BUSINESS: {
            code: 'YEARS_IN_BUSINESS',
            name: 'YEARS_IN_BUSINESS',
        },
        JOB_DEPARTMENT: {
            code: 'JOB_DEPARTMENT',
            name: 'JOB_DEPARTMENT',
        },
        JOB_ROLE: {
            code: 'JOB_ROLE',
            name: 'JOB_ROLE',
        },
        EDUCATION_PROGRAM: {
            code: 'EDUCATION_PROGRAM',
            name: 'EDUCATION_PROGRAM',
        },
        EDUCATION_COURSE: {
            code: 'EDUCATION_COURSE',
            name: 'EDUCATION_COURSE',
        },
        PRODUCT: {
            code: 'PRODUCT',
            name: 'PRODUCT',
        },
        SERVICE: {
            code: 'SERVICE',
            name: 'SERVICE',
        },
        OFFER: {
            code: 'OFFER',
            name: 'OFFER',
        },
        CATEGORY: {
            code: 'CATEGORY',
            name: 'CATEGORY',
        },
        PREFERRED_CONTACT_METHOD: {
            code: 'PREFERRED_CONTACT_METHOD',
            name: 'PREFERRED_CONTACT_METHOD',
        },
        PREFERRED_CONTACT_TIME: {
            code: 'PREFERRED_CONTACT_TIME',
            name: 'PREFERRED_CONTACT_TIME',
        },
        PURCHASE_TIMELINE: {
            code: 'PURCHASE_TIMELINE',
            name: 'PURCHASE_TIMELINE',
        },
        YEARS_OF_EXPERIENCE: {
            code: 'YEARS_OF_EXPERIENCE',
            name: 'YEARS_OF_EXPERIENCE',
        },
        JOB_INDUSTRY: {
            code: 'JOB_INDUSTRY',
            name: 'JOB_INDUSTRY',
        },

        LEVEL_OF_EDUCATION: {
            code: 'LEVEL_OF_EDUCATION',
            name: 'LEVEL_OF_EDUCATION',
        },
        PROPERTY_TYPE: {
            code: 'PROPERTY_TYPE',
            name: 'PROPERTY_TYPE',
        },
        REALTOR_HELP_GOAL: {
            code: 'REALTOR_HELP_GOAL',
            name: 'REALTOR_HELP_GOAL',
        },
        PROPERTY_COMMUNITY: {
            code: 'PROPERTY_COMMUNITY',
            name: 'PROPERTY_COMMUNITY',
        },
        PRICE_RANGE: {
            code: 'PRICE_RANGE',
            name: 'PRICE_RANGE',
        },
        NUMBER_OF_BEDROOMS: {
            code: 'NUMBER_OF_BEDROOMS',
            name: 'NUMBER_OF_BEDROOMS',
        },
        FURNISHED_PROPERTY: {
            code: 'FURNISHED_PROPERTY',
            name: 'FURNISHED_PROPERTY',
        },
        PETS_ALLOWED_PROPERTY: {
            code: 'PETS_ALLOWED_PROPERTY',
            name: 'PETS_ALLOWED_PROPERTY',
        },
        NEXT_PLANNED_PURCHASE: {
            code: 'NEXT_PLANNED_PURCHASE',
            name: 'NEXT_PLANNED_PURCHASE',
        },
        EVENT_SIGNUP_INTEREST: {
            code: 'EVENT_SIGNUP_INTEREST',
            name: 'EVENT_SIGNUP_INTEREST',
        },
        PREFERRED_SHOPPING_PLACES: {
            code: 'PREFERRED_SHOPPING_PLACES',
            name: 'PREFERRED_SHOPPING_PLACES',
        },
        FAVORITE_BRAND: {
            code: 'FAVORITE_BRAND',
            name: 'FAVORITE_BRAND',
        },
        TRANSPORTATION_COMMERCIAL_LICENSE_TYPE: {
            code: 'TRANSPORTATION_COMMERCIAL_LICENSE_TYPE',
            name: 'TRANSPORTATION_COMMERCIAL_LICENSE_TYPE',
        },
        EVENT_BOOKING_INTEREST: {
            code: 'EVENT_BOOKING_INTEREST',
            name: 'EVENT_BOOKING_INTEREST',
        },
        DESTINATION_COUNTRY: {
            code: 'DESTINATION_COUNTRY',
            name: 'DESTINATION_COUNTRY',
        },
        DESTINATION_CITY: {
            code: 'DESTINATION_CITY',
            name: 'DESTINATION_CITY',
        },
        DEPARTURE_COUNTRY: {
            code: 'DEPARTURE_COUNTRY',
            name: 'DEPARTURE_COUNTRY',
        },
        DEPARTURE_CITY: {
            code: 'DEPARTURE_CITY',
            name: 'DEPARTURE_CITY',
        },
        DEPARTURE_DATE: {
            code: 'DEPARTURE_DATE',
            name: 'DEPARTURE_DATE',
        },
        RETURN_DATE: {
            code: 'RETURN_DATE',
            name: 'RETURN_DATE',
        },
        NUMBER_OF_TRAVELERS: {
            code: 'NUMBER_OF_TRAVELERS',
            name: 'NUMBER_OF_TRAVELERS',
        },
        TRAVEL_BUDGET: {
            code: 'TRAVEL_BUDGET',
            name: 'TRAVEL_BUDGET',
        },
        TRAVEL_ACCOMMODATION: {
            code: 'TRAVEL_ACCOMMODATION',
            name: 'TRAVEL_ACCOMMODATION',
        },

    },
    CONDITIONS: {
        FULL_NAME: {
            code: 'FULL_NAME',
            name: i18n.t('SEGMENTS.FULL_NAME'),
            data_type: 'FULL_NAME',
        },
        EMAIL: {
            code: 'EMAIL',
            name: i18n.t('SEGMENTS.EMAIL'),
            data_type: 'EMAIL',
        },
        PHONE: {
            code: 'PHONE',
            name: i18n.t('SEGMENTS.PHONE'),
            data_type: 'PHONE',
        },
        TAG: {
            code: 'TAG',
            name: i18n.t('SEGMENTS.TAG'),
            data_type: 'TAG',
        },
        DOB: {
            code: 'DOB',
            name: i18n.t('SEGMENTS.DOB'),
            data_type: 'DOB',
        },
        GENDER: {
            code: 'GENDER',
            name: i18n.t('SEGMENTS.GENDER'),
            data_type: 'GENDER',
        },
        CREATED_AT: {
            code: 'CREATED_AT',
            name: i18n.t('SEGMENTS.CREATED_AT'),
            data_type: 'CREATED_AT',
        },
        SUBSCRIBED_AT: {
            code: 'SUBSCRIBED_AT',
            name: i18n.t('SEGMENTS.SUBSCRIBED_AT'),
            data_type: 'SUBSCRIBED_AT',
        },
        UNSUBSCRIBED_AT: {
            code: 'UNSUBSCRIBED_AT',
            name: i18n.t('SEGMENTS.UNSUBSCRIBED_AT'),
            data_type: 'UNSUBSCRIBED_AT',
        },
        LOCALE: {
            code: 'LOCALE',
            name: i18n.t('SEGMENTS.LOCALE'),
            data_type: 'LOCALE',
        },
        COUNTRY: {
            code: 'COUNTRY',
            name: i18n.t('SEGMENTS.COUNTRY'),
            data_type: 'COUNTRY',
        },
        CITY: {
            code: 'CITY',
            name: i18n.t('SEGMENTS.CITY'),
            data_type: 'CITY',
        },
        DISTRICT: {
            code: 'DISTRICT',
            name: i18n.t('SEGMENTS.DISTRICT'),
            data_type: 'DISTRICT',
        },
        WARD: {
            code: 'WARD',
            name: i18n.t('SEGMENTS.WARD'),
            data_type: 'WARD',
        },
        POSTAL_CODE: {
            code: 'POSTAL_CODE',
            name: i18n.t('SEGMENTS.POSTAL_CODE'),
            data_type: 'POSTAL_CODE',
        },
        ADDRESS: {
            code: 'ADDRESS',
            name: i18n.t('SEGMENTS.ADDRESS'),
            data_type: 'ADDRESS',
        },
        ADDRESS_2: {
            code: 'ADDRESS_2',
            name: i18n.t('SEGMENTS.ADDRESS_2'),
            data_type: 'ADDRESS_2',
        },
        TIMEZONE: {
            code: 'TIME_ZONE',
            name: i18n.t('SEGMENTS.TIMEZONE'),
            data_type: 'TIME_ZONE',
        },

        SEGMENT: {
            code: 'SEGMENT',
            name: i18n.t('SEGMENTS.SEGMENT'),
            data_type: 'SEGMENT',
        },
        COMPANY: {
            code: 'COMPANY',
            name: i18n.t('SEGMENTS.COMPANY'),
            data_type: 'COMPANY',
        },
        JOB_TITLE: {
            code: 'JOB_TITLE',
            name: i18n.t('SEGMENTS.JOB_TITLE'),
            data_type: 'JOB_TITLE',
        },
        EXTERNAL_CUSTOMER_ID: {
            code: 'EXTERNAL_CUSTOMER_ID',
            name: i18n.t('SEGMENTS.EXTERNAL_CUSTOMER_ID'),
            data_type: 'EXTERNAL_CUSTOMER_ID',
        },
        PAGE_ID: {
            code: 'PAGE_ID',
            name: i18n.t('SEGMENTS.PAGE_ID'),
            data_type: 'PAGE_ID',
        },
        OA_ID: {
            code: 'OA_ID',
            name: i18n.t('SEGMENTS.OA_ID'),
            data_type: 'OA_ID',
        },
        ZALO_UID: {
            code: 'ZALO_UID',
            name: i18n.t('SEGMENTS.ZALO_UID'),
            data_type: 'ZALO_UID',
        },

        SCORE: {
            code: 'SCORE',
            name: i18n.t('SEGMENTS.SCORE'),
            data_type: 'SCORE',
        },
        CHANNELS: {
            code: 'CHANNELS',
            name: i18n.t('SEGMENTS.CHANNELS'),
            data_type: 'CHANNELS',
        },
        LAST_URL: {
            code: 'LAST_URL',
            name: i18n.t('SEGMENTS.LAST_URL'),
            data_type: 'LAST_URL',
        },
        LAST_IP: {
            code: 'LAST_IP',
            name: i18n.t('SEGMENTS.LAST_IP'),
            data_type: 'LAST_IP',
        },
        UTM_SOURCE: {
            code: 'UTM_SOURCE',
            name: i18n.t('SEGMENTS.UTM_SOURCE'),
            data_type: 'UTM_SOURCE',
        },
        UTM_CAMPAIGN: {
            code: 'UTM_CAMPAIGN',
            name: i18n.t('SEGMENTS.UTM_CAMPAIGN'),
            data_type: 'UTM_CAMPAIGN',
        },
        UTM_TERM: {
            code: 'UTM_TERM',
            name: i18n.t('SEGMENTS.UTM_TERM'),
            data_type: 'UTM_TERM',
        },
        UTM_CONTENT: {
            code: 'UTM_CONTENT',
            name: i18n.t('SEGMENTS.UTM_CONTENT'),
            data_type: 'UTM_CONTENT',
        },
        UTM_MEDIUM: {
            code: 'UTM_MEDIUM',
            name: i18n.t('SEGMENTS.UTM_MEDIUM'),
            data_type: 'UTM_MEDIUM',
        },
        FACEBOOK_URL: {
            code: 'FACEBOOK_URL',
            name: i18n.t('SEGMENTS.FACEBOOK_URL'),
            data_type: 'FACEBOOK_URL',
        },
        TWITTER_URL: {
            code: 'TWITTER_URL',
            name: i18n.t('SEGMENTS.TWITTER_URL'),
            data_type: 'TWITTER_URL',
        },
        LINKEDIN_URL: {
            code: 'LINKEDIN_URL',
            name: i18n.t('SEGMENTS.LINKEDIN_URL'),
            data_type: 'LINKEDIN_URL',
        },
        ZALO: {
            code: 'ZALO',
            name: i18n.t('SEGMENTS.ZALO'),
            data_type: 'ZALO',
        },
        SKYPE: {
            code: 'SKYPE',
            name: i18n.t('SEGMENTS.SKYPE'),
            data_type: 'SKYPE',
        },
        WEBSITE: {
            code: 'WEBSITE',
            name: i18n.t('SEGMENTS.WEBSITE'),
            data_type: 'WEBSITE',
        },
        IS_EMAIL_VERIFIED: {
            code: 'IS_EMAIL_VERIFIED',
            name: i18n.t('SEGMENTS.IS_EMAIL_VERIFIED'),
            data_type: 'IS_EMAIL_VERIFIED',
        },
        EMAIL_SUBSCRIBE_STATUS: {
            code: 'EMAIL_SUBSCRIBE_STATUS',
            name: i18n.t('SEGMENTS.EMAIL_SUBSCRIBE_STATUS'),
            data_type: 'EMAIL_SUBSCRIBE_STATUS',
        },
        BOUNCE: {
            code: 'BOUNCE',
            name: 'Bounce ',
            data_type: 'COMPLAINT',
        },
        COMPLAINT: {
            code: 'COMPLAINT',
            name: 'Complaint',
            data_type: 'COMPLAINT',
        },
        VOUCHERS: {
            code: 'VOUCHERS',
            name: i18n.t('SEGMENTS.VOUCHERS'),
            data_type: 'VOUCHERS',
        },
        TOTAL_ORDER: {
            code: 'TOTAL_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_ORDER'),
            data_type: 'TOTAL_ORDER',
        },
        TOTAL_ORDER_VALUE: {
            code: 'TOTAL_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_ORDER_VALUE'),
            data_type: 'TOTAL_ORDER_VALUE',
        },
        AVG_ORDER_VALUE: {
            code: 'AVG_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_ORDER_VALUE'),
            data_type: 'AVG_ORDER_VALUE',
        },
        TOTAL_PENDING_ORDER: {
            code: 'TOTAL_PENDING_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_PENDING_ORDER'),
            data_type: 'TOTAL_ORDER',
        },
        TOTAL_PENDING_ORDER_VALUE: {
            code: 'TOTAL_PENDING_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_PENDING_ORDER_VALUE'),
            data_type: 'TOTAL_PENDING_ORDER_VALUE',
        },
        AVG_PENDING_ORDER_VALUE: {
            code: 'AVG_PENDING_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_PENDING_ORDER_VALUE'),
            data_type: 'AVG_PENDING_ORDER_VALUE',
        },
        TOTAL_PAID_ORDER: {
            code: 'TOTAL_PAID_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_PAID_ORDER'),
            data_type: 'TOTAL_PAID_ORDER',
        },
        TOTAL_PAID_ORDER_VALUE: {
            code: 'TOTAL_PAID_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_PAID_ORDER_VALUE'),
            data_type: 'TOTAL_PAID_ORDER_VALUE',
        },
        AVG_PAID_ORDER_VALUE: {
            code: 'AVG_PAID_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_PAID_ORDER_VALUE'),
            data_type: 'AVG_PAID_ORDER_VALUE',
        },
        TOTAL_CANCEL_ORDER: {
            code: 'TOTAL_CANCEL_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_CANCEL_ORDER'),
            data_type: 'TOTAL_CANCEL_ORDER',
        },
        TOTAL_CANCEL_ORDER_VALUE: {
            code: 'TOTAL_CANCEL_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_CANCEL_ORDER_VALUE'),
            data_type: 'TOTAL_CANCEL_ORDER_VALUE',
        },
        AVG_CANCEL_ORDER_VALUE: {
            code: 'AVG_CANCEL_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_CANCEL_ORDER_VALUE'),
            data_type: 'AVG_CANCEL_ORDER_VALUE',
        },
    },
    SYSTEM_FIELD: {
        FULL_NAME: {
            code: 'FULL_NAME',
            name: i18n.t('SEGMENTS.FULL_NAME'),
            data_type: 'FULL_NAME',
        },
        EMAIL: {
            code: 'EMAIL',
            name: i18n.t('SEGMENTS.EMAIL'),
            data_type: 'EMAIL',
        },
        PHONE: {
            code: 'PHONE',
            name: i18n.t('SEGMENTS.PHONE'),
            data_type: 'PHONE',
        },
        TAG: {
            code: 'TAG',
            name: i18n.t('SEGMENTS.TAG'),
            data_type: 'TAG',
        },
        DOB: {
            code: 'DOB',
            name: i18n.t('SEGMENTS.DOB'),
            data_type: 'DOB',
        },
        GENDER: {
            code: 'GENDER',
            name: i18n.t('SEGMENTS.GENDER'),
            data_type: 'GENDER',
        },
        CREATED_AT: {
            code: 'CREATED_AT',
            name: i18n.t('SEGMENTS.CREATED_AT'),
            data_type: 'CREATED_AT',
        },
        SUBSCRIBED_AT: {
            code: 'SUBSCRIBED_AT',
            name: i18n.t('SEGMENTS.SUBSCRIBED_AT'),
            data_type: 'SUBSCRIBED_AT',
        },
        // UNSUBSCRIBED_AT: {
        //     code: 'UNSUBSCRIBED_AT',
        //     name: i18n.t('SEGMENTS.UNSUBSCRIBED_AT'),
        //     data_type: 'UNSUBSCRIBED_AT',
        // },
        LOCALE: {
            code: 'LOCALE',
            name: i18n.t('SEGMENTS.LOCALE'),
            data_type: 'LOCALE',
        },
        COUNTRY: {
            code: 'COUNTRY',
            name: i18n.t('SEGMENTS.COUNTRY'),
            data_type: 'COUNTRY',
        },
        CITY: {
            code: 'CITY',
            name: i18n.t('SEGMENTS.CITY'),
            data_type: 'CITY',
        },
        DISTRICT: {
            code: 'DISTRICT',
            name: i18n.t('SEGMENTS.DISTRICT'),
            data_type: 'DISTRICT',
        },
        WARD: {
            code: 'WARD',
            name: i18n.t('SEGMENTS.WARD'),
            data_type: 'WARD',
        },
        POSTAL_CODE: {
            code: 'POSTAL_CODE',
            name: i18n.t('SEGMENTS.POSTAL_CODE'),
            data_type: 'POSTAL_CODE',
        },
        ADDRESS: {
            code: 'ADDRESS',
            name: i18n.t('SEGMENTS.ADDRESS'),
            data_type: 'ADDRESS',
        },
        ADDRESS_2: {
            code: 'ADDRESS_2',
            name: i18n.t('SEGMENTS.ADDRESS_2'),
            data_type: 'ADDRESS_2',
        },
        TIMEZONE: {
            code: 'TIME_ZONE',
            name: i18n.t('SEGMENTS.TIMEZONE'),
            data_type: 'TIME_ZONE',
        },

        SEGMENT: {
            code: 'SEGMENT',
            name: i18n.t('SEGMENTS.SEGMENT'),
            data_type: 'SEGMENT',
        },
        COMPANY: {
            code: 'COMPANY',
            name: i18n.t('SEGMENTS.COMPANY'),
            data_type: 'COMPANY',
        },
        JOB_TITLE: {
            code: 'JOB_TITLE',
            name: i18n.t('SEGMENTS.JOB_TITLE'),
            data_type: 'JOB_TITLE',
        },
        EXTERNAL_CUSTOMER_ID: {
            code: 'EXTERNAL_CUSTOMER_ID',
            name: i18n.t('SEGMENTS.EXTERNAL_CUSTOMER_ID'),
            data_type: 'EXTERNAL_CUSTOMER_ID',
        },
        PAGE_ID: {
            code: 'PAGE_ID',
            name: i18n.t('SEGMENTS.PAGE_ID'),
            data_type: 'PAGE_ID',
        },
        OA_ID: {
            code: 'OA_ID',
            name: i18n.t('SEGMENTS.OA_ID'),
            data_type: 'OA_ID',
        },
        ZALO_UID: {
            code: 'ZALO_UID',
            name: i18n.t('SEGMENTS.ZALO_UID'),
            data_type: 'ZALO_UID',
        },
        SCORE: {
            code: 'SCORE',
            name: i18n.t('SEGMENTS.SCORE'),
            data_type: 'SCORE',
        },
        IS_EMAIL_VERIFIED: {
            code: 'IS_EMAIL_VERIFIED',
            name: i18n.t('SEGMENTS.IS_EMAIL_VERIFIED'),
            data_type: 'IS_EMAIL_VERIFIED',
        },
        EMAIL_SUBSCRIBE_STATUS: {
            code: 'EMAIL_SUBSCRIBE_STATUS',
            name: i18n.t('SEGMENTS.EMAIL_SUBSCRIBE_STATUS'),
            data_type: 'EMAIL_SUBSCRIBE_STATUS',
        },
        BOUNCE: {
            code: 'BOUNCE',
            name: 'Bounce ',
            data_type: 'COMPLAINT',
        },
        COMPLAINT: {
            code: 'COMPLAINT',
            name: 'Complaint',
            data_type: 'COMPLAINT',
        },
        VOUCHERS: {
            code: 'VOUCHERS',
            name: i18n.t('SEGMENTS.VOUCHERS'),
            data_type: 'VOUCHERS',
        },
        TOTAL_ORDER: {
            code: 'TOTAL_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_ORDER'),
            data_type: 'TOTAL_ORDER',
        },
        TOTAL_ORDER_VALUE: {
            code: 'TOTAL_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_ORDER_VALUE'),
            data_type: 'TOTAL_ORDER_VALUE',
        },
        AVG_ORDER_VALUE: {
            code: 'AVG_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_ORDER_VALUE'),
            data_type: 'AVG_ORDER_VALUE',
        },
        TOTAL_PENDING_ORDER: {
            code: 'TOTAL_PENDING_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_PENDING_ORDER'),
            data_type: 'TOTAL_ORDER',
        },
        TOTAL_PENDING_ORDER_VALUE: {
            code: 'TOTAL_PENDING_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_PENDING_ORDER_VALUE'),
            data_type: 'TOTAL_PENDING_ORDER_VALUE',
        },
        AVG_PENDING_ORDER_VALUE: {
            code: 'AVG_PENDING_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_PENDING_ORDER_VALUE'),
            data_type: 'AVG_PENDING_ORDER_VALUE',
        },
        TOTAL_PAID_ORDER: {
            code: 'TOTAL_PAID_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_PAID_ORDER'),
            data_type: 'TOTAL_PAID_ORDER',
        },
        TOTAL_PAID_ORDER_VALUE: {
            code: 'TOTAL_PAID_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_PAID_ORDER_VALUE'),
            data_type: 'TOTAL_PAID_ORDER_VALUE',
        },
        AVG_PAID_ORDER_VALUE: {
            code: 'AVG_PAID_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_PAID_ORDER_VALUE'),
            data_type: 'AVG_PAID_ORDER_VALUE',
        },
        TOTAL_CANCEL_ORDER: {
            code: 'TOTAL_CANCEL_ORDER',
            name: i18n.t('SEGMENTS.TOTAL_CANCEL_ORDER'),
            data_type: 'TOTAL_CANCEL_ORDER',
        },
        TOTAL_CANCEL_ORDER_VALUE: {
            code: 'TOTAL_CANCEL_ORDER_VALUE',
            name: i18n.t('SEGMENTS.TOTAL_CANCEL_ORDER_VALUE'),
            data_type: 'TOTAL_CANCEL_ORDER_VALUE',
        },
        AVG_CANCEL_ORDER_VALUE: {
            code: 'AVG_CANCEL_ORDER_VALUE',
            name: i18n.t('SEGMENTS.AVG_CANCEL_ORDER_VALUE'),
            data_type: 'AVG_CANCEL_ORDER_VALUE',
        },
    },
    TRACKING_SOURCE: {
        CHANNELS: {
            code: 'CHANNELS',
            name: i18n.t('SEGMENTS.CHANNELS'),
            data_type: 'CHANNELS',
        },
        LAST_URL: {
            code: 'LAST_URL',
            name: i18n.t('SEGMENTS.LAST_URL'),
            data_type: 'LAST_URL',
        },
        LAST_IP: {
            code: 'LAST_IP',
            name: i18n.t('SEGMENTS.LAST_IP'),
            data_type: 'LAST_IP',
        },
        UTM_SOURCE: {
            code: 'UTM_SOURCE',
            name: i18n.t('SEGMENTS.UTM_SOURCE'),
            data_type: 'UTM_SOURCE',
        },
        UTM_CAMPAIGN: {
            code: 'UTM_CAMPAIGN',
            name: i18n.t('SEGMENTS.UTM_CAMPAIGN'),
            data_type: 'UTM_CAMPAIGN',
        },
        UTM_TERM: {
            code: 'UTM_TERM',
            name: i18n.t('SEGMENTS.UTM_TERM'),
            data_type: 'UTM_TERM',
        },
        UTM_CONTENT: {
            code: 'UTM_CONTENT',
            name: i18n.t('SEGMENTS.UTM_CONTENT'),
            data_type: 'UTM_CONTENT',
        },
        UTM_MEDIUM: {
            code: 'UTM_MEDIUM',
            name: i18n.t('SEGMENTS.UTM_MEDIUM'),
            data_type: 'UTM_MEDIUM',
        },
    },
    SOCIAL: {
        FACEBOOK_URL: {
            code: 'FACEBOOK_URL',
            name: i18n.t('SEGMENTS.FACEBOOK_URL'),
            data_type: 'FACEBOOK_URL',
        },
        TWITTER_URL: {
            code: 'TWITTER_URL',
            name: i18n.t('SEGMENTS.TWITTER_URL'),
            data_type: 'TWITTER_URL',
        },
        LINKEDIN_URL: {
            code: 'LINKEDIN_URL',
            name: i18n.t('SEGMENTS.LINKEDIN_URL'),
            data_type: 'LINKEDIN_URL',
        },
        ZALO: {
            code: 'ZALO',
            name: i18n.t('SEGMENTS.ZALO'),
            data_type: 'ZALO',
        },
        SKYPE: {
            code: 'SKYPE',
            name: i18n.t('SEGMENTS.SKYPE'),
            data_type: 'SKYPE',
        },
        WEBSITE: {
            code: 'WEBSITE',
            name: i18n.t('SEGMENTS.WEBSITE'),
            data_type: 'WEBSITE',
        },
    },
    TRIGGER_CONDITION: {
        TAG_APPLIED: {
            code: 'TAG_APPLIED',
            name: 'Tag Applied',
        },
        TAG_REMOVED: {
            code: 'TAG_REMOVED',
            name: 'Tag Removed',
        },
        SEQUENCE_SUBSCRIBED: {
            code: 'SEQUENCE_SUBSCRIBED',
            name: 'Subscribed To Sequence',
        },
        SEQUENCE_UNSUBSCRIBED: {
            code: 'SEQUENCE_UNSUBSCRIBED',
            name: 'Unsubscribed To Sequence',
        },
        CUSTOM_FIELD_VALUE_CHANGED: {
            code: 'CUSTOM_FIELD_VALUE_CHANGED',
            name: 'Custom field value changed',
        },
        SYSTEM_FIELD_VALUE_CHANGED: {
            code: 'SYSTEM_FIELD_VALUE_CHANGED',
            name: 'System field value changed',
        },
        LEAD_FORM: {
            code: 'LEAD_FORM',
            name: 'Lead Form',
        },
        COMPLETED_SEQUENCE: {
            code: 'COMPLETED_SEQUENCE',
            name: 'Completed Sequece',
        },
        FORM: {
            code: 'FORM',
            name: 'Form',
        },
    },
    CONDITION_KEYWORDS: {
        KEYWORDS: {
            code: 'KEYWORDS',
            name: i18n.t('SEGMENTS.KEYWORDS'),
        },
    },
    CONDITION_CUSTOM_FIELD: {
        AUTO_INCREMENT: {
            code: 'AUTO_INCREMENT',
            name: i18n.t('SEGMENTS.AUTO_INCREMENT'),
            data_type: 'AUTO_INCREMENT',
        },
        NUMBER: {
            code: 'NUMBER',
            name: i18n.t('SEGMENTS.NUMBER'),
            data_type: 'NUMBER',
        },
        CHECK_BOX: {
            code: 'CHECK_BOX',
            name: i18n.t('SEGMENTS.CHECK_BOX'),
            data_type: 'CHECK_BOX',
        },
        DROPDOWN: {
            code: 'DROPDOWN',
            name: i18n.t('SEGMENTS.DROPDOWN'),
            data_type: 'DROPDOWN',
        },
        DATE: {
            code: 'DATE',
            name: i18n.t('SEGMENTS.DATE'),
            data_type: 'DATE',
        },
        BOOLEAN: {
            code: 'BOOLEAN',
            name: i18n.t('SEGMENTS.BOOLEAN'),
            data_type: 'BOOLEAN',
        },
    },
    OPERATORS: {
        EQUAL: {
            code: 'EQUAL',
            name: i18n.t('SEGMENTS.OPERATOR_EQUAL'),
        },
        NOT_EQUAL: {
            code: 'NOT_EQUAL',
            name: i18n.t('SEGMENTS.OPERATOR_NOT_EQUAL'),
        },
        GREATER_THAN: {
            code: 'GREATER_THAN',
            name: i18n.t('SEGMENTS.OPERATOR_GREATER_THAN'),
        },
        LESS_THAN: {
            code: 'LESS_THAN',
            name: i18n.t('SEGMENTS.OPERATOR_LESS_THAN'),
        },
        GREATER_THAN_OR_EQUAL: {
            code: 'GREATER_THAN_OR_EQUAL',
            name: i18n.t('SEGMENTS.OPERATOR_GREATER_THAN_OR_EQUAL'),
        },
        LESS_THAN_OR_EQUAL: {
            code: 'LESS_THAN_OR_EQUAL',
            name: i18n.t('SEGMENTS.OPERATOR_LESS_THAN_OR_EQUAL'),
        },
        IS_EXACTLY: {
            code: 'IS_EXACTLY',
            name: i18n.t('SEGMENTS.IS_EXACTLY'),
        },
        HAS_ANY_VALUE: {
            code: 'HAS_ANY_VALUE',
            name: i18n.t('SEGMENTS.HAS_ANY_VALUE'),
        },
        CONTAINS: {
            code: 'CONTAINS',
            name: i18n.t('SEGMENTS.CONTAINS'),
        },
        NOT_CONTAIN: {
            code: 'NOT_CONTAIN',
            name: i18n.t('SEGMENTS.NOT_CONTAIN'),
        },
        BEGIN_WITH: {
            code: 'BEGIN_WITH',
            name: i18n.t('SEGMENTS.BEGIN_WITH'),
        },
        IS_UNKNOWN: {
            code: 'IS_UNKNOWN',
            name: i18n.t('SEGMENTS.IS_UNKNOWN'),
        },
        AFTER: {
            code: 'AFTER',
            name: i18n.t('SEGMENTS.AFTER'),
        },
        BEFORE: {
            code: 'BEFORE',
            name: i18n.t('SEGMENTS.BEFORE'),
        },
        AFTER_CURRENT_DATE: {
            code: 'AFTER_CURRENT_DATE',
            name: i18n.t('SEGMENTS.AFTER_CURRENT_DATE'),
        },
        BEFORE_CURRENT_DATE: {
            code: 'BEFORE_CURRENT_DATE',
            name: i18n.t('SEGMENTS.BEFORE_CURRENT_DATE'),
        },
        ON: {
            code: 'ON',
            name: i18n.t('SEGMENTS.ON'),
        },
        TRUE: {
            code: 'TRUE',
            name: i18n.t('SEGMENTS.TRUE'),
        },
        FALSE: {
            code: 'FALSE',
            name: i18n.t('SEGMENTS.FALSE'),
        },
        HAS_CHANGED_TO: {
            code: 'HAS_CHANGED_TO',
            name: i18n.t('SEGMENTS.HAS_CHANGED_TO'),
        },
        HAS_CHANGED_AND_NOT_EQUAL: {
            code: 'HAS_CHANGED_AND_NOT_EQUAL',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_NOT_EQUAL'),
        },
        HAS_CHANGED_AND_CONTAINS: {
            code: 'HAS_CHANGED_AND_CONTAINS',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_CONTAINS'),
        },
        HAS_CHANGED_AND_NOT_CONTAIN: {
            code: 'HAS_CHANGED_AND_NOT_CONTAIN',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_NOT_CONTAIN'),
        },
        HAS_CHANGED_AND_BEGINS_WITH: {
            code: 'HAS_CHANGED_AND_BEGINS_WITH',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_BEGINS_WITH'),
        },
        HAS_CHANGED_TO_ANY_VALUE: {
            code: 'HAS_CHANGED_TO_ANY_VALUE',
            name: i18n.t('SEGMENTS.HAS_CHANGED_TO_ANY_VALUE'),
        },
        HAS_CHANGED_TO_EMPTY_VALUE: {
            code: 'HAS_CHANGED_TO_EMPTY_VALUE',
            name: i18n.t('SEGMENTS.HAS_CHANGED_TO_EMPTY_VALUE'),
        },
        HAS_CHANGED_AND_IN_LIST: {
            code: 'HAS_CHANGED_AND_IN_LIST',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_IN_LIST'),
        },
        HAS_CHANGED_AND_GREATER_THAN: {
            code: 'HAS_CHANGED_AND_GREATER_THAN',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_GREATER_THAN'),
        },
        HAS_CHANGED_AND_LESS_THAN: {
            code: 'HAS_CHANGED_AND_LESS_THAN',
            name: i18n.t('SEGMENTS.HAS_CHANGED_AND_LESS_THAN'),
        },
        VALUE_NOT_CHANGED: {
            code: 'VALUE_NOT_CHANGED',
            name: i18n.t('SEGMENTS.VALUE_NOT_CHANGED'),
        },
    },
    LANGUAGE: {
        VI: {
            code: 'vi',
            name: i18n.t('SETTINGS.VI'),
        },
        EN: {
            code: 'en',
            name: i18n.t('SETTINGS.EN'),
        },
        OTHER: {
            code: 'other',
            name: i18n.t('SETTINGS.OTHER'),
        },
    },
    LANGUAGE_SYSTEM: {
        VI: {
            code: 'vi',
            name: i18n.t('SETTINGS.VI'),
        },
        EN: {
            code: 'en',
            name: i18n.t('SETTINGS.EN'),
        },
    },
    TIMEZONE: {
        MINUS_TWELVE: {
            code: -12,
            name: 'UTC-12:00',
        },
        MINUS_ELEVEN: {
            code: -11,
            name: 'UTC-11:00',
        },
        MINUS_TEN: {
            code: -10,
            name: 'UTC-10:00',
        },
        MINUS_NINE: {
            code: -9,
            name: 'UTC-9:00',
        },
        MINUS_EIGHT: {
            code: -8,
            name: 'UTC-8:00',
        },
        MINUS_SEVEN: {
            code: -7,
            name: 'UTC-7:00',
        },
        MINUS_SIX: {
            code: -6,
            name: 'UTC-6:00',
        },
        MINUS_FIVE: {
            code: -5,
            name: 'UTC-5:00',
        },
        MINUS_FOUR: {
            code: -4,
            name: 'UTC-4:00',
        },
        MINUS_THREE: {
            code: -3,
            name: 'UTC-3:00',
        },
        MINUS_TWO: {
            code: -2,
            name: 'UTC-2:00',
        },
        MINUS_ONE: {
            code: -1,
            name: 'UTC-1:00',
        },
        ZERO: {
            code: 0,
            name: 'UTC+0:00',
        },
        ONE: {
            code: 1,
            name: 'UTC+1:00',
        },
        TWO: {
            code: 2,
            name: 'UTC+2:00',
        },
        THREE: {
            code: 3,
            name: 'UTC+3:00',
        },
        FOUR: {
            code: 4,
            name: 'UTC+4:00',
        },
        FIVE: {
            code: 5,
            name: 'UTC+5:00',
        },
        SIX: {
            code: 6,
            name: 'UTC+6:00',
        },
        SEVEN: {
            code: 7,
            name: 'UTC+7:00',
        },
        EIGHT: {
            code: 8,
            name: 'UTC+8:00',
        },
        NINE: {
            code: 9,
            name: 'UTC+9:00',
        },
        TEN: {
            code: 10,
            name: 'UTC+10:00',
        },
        ELEVEN: {
            code: 11,
            name: 'UTC+11:00',
        },
        TWELVE: {
            code: 12,
            name: 'UTC+12:00',
        },
        THIRTEEN: {
            code: 13,
            name: 'UTC+13:00',
        },
        FOURTEEN: {
            code: 14,
            name: 'UTC+14:00',
        },
    },
    EXPORT_ORDER: {
        ON_PAGE: 1,
        ALL_ORDER: 2,
        SELECTED_ORDER: 3,
    },
    EXPORT_CUSTOMER: {
        ON_PAGE: '1',
        SELECTED_CUSTOMER: '2',
        ALL_CUSTOMER_CONDITION: '3',
        ALL_CUSTOMER: '4',
    },
    EXPORT_TYPE: {
        ALL: 'all',
        ALL_CUSTOMER_CONDITION: 'ALL_CUSTOMER_CONDITION',
    },
    CHANNELS: ['DASHBOARD', 'LADIPAGE', 'LADISALES', 'LADICHAT', 'MESSENGER', 'HARAVAN', 'SAPO', 'SHOPIFY', 'WORDPRESS', 'KIOTVIET', 'NHANHVN', 'SMS', 'EMAIL',
        'INSTAGRAM', 'WHATSAPP', 'API', 'WPFORM7', 'WPFORMS', 'WOOCOMMERCE', 'FACEBOOK', 'FACEBOOK_LEAD_ADS', 'CRMVIET', 'GOOGLE_LEAD_ADS', 'ELEMENTOR'],
    CHANNELS_CUSTOMER: [
        {
            code: 'DASHBOARD',
            name: 'DASHBOARD',
        },
        {
            code: 'LADIPAGE',
            name: 'LADIPAGE',
        },
        {
            code: 'LADISALES',
            name: 'LADISALES',
        },
        {
            code: 'LADICHAT',
            name: 'LADICHAT',
        },
        {
            code: 'MESSENGER',
            name: 'MESSENGER',
        },
        {
            code: 'HARAVAN',
            name: 'HARAVAN',
        },
        {
            code: 'SAPO',
            name: 'SAPO',
        },
        {
            code: 'SHOPIFY',
            name: 'SHOPIFY',
        },
        {
            code: 'WORDPRESS',
            name: 'WORDPRESS',
        },
        {
            code: 'KIOTVIET',
            name: 'KIOTVIET',
        },
        {
            code: 'NHANHVN',
            name: 'NHANHVN',
        },
        {
            code: 'EMAIL',
            name: 'EMAIL',
        },
        {
            code: 'INSTAGRAM',
            name: 'INSTAGRAM',
        },
        {
            code: 'WHATSAPP',
            name: 'WHATSAPP',
        },
        {
            code: 'API',
            name: 'API',
        },
        {
            code: 'WPFORM7',
            name: 'WPFORM7',
        },
        {
            code: 'WPFORMS',
            name: 'WPFORMS',
        },
        {
            code: 'WOOCOMMERCE',
            name: 'WOOCOMMERCE',
        },
        {
            code: 'FACEBOOK',
            name: 'FACEBOOK',
        },
        {
            code: 'FACEBOOK_LEAD_ADS',
            name: 'FACEBOOK_LEAD_ADS',
        },
        {
            code: 'CRMVIET',
            name: 'CRMVIET',
        },
        {
            code: 'GOOGLE_LEAD_ADS',
            name: 'GOOGLE_LEAD_ADS',
        },
        {
            code: 'ELEMENTOR',
            name: 'ELEMENTOR',
        },
    ],
    ICON: {
        SAPO: 'https://w.ladicdn.com/s600x600/5e548427be8067214346f471/gcalls-logo-doi-tac-sapo-1-20210708084900.png',
        LADILINK: 'https://w.ladicdn.com/source/v3/icon_ladilink_origin.svg',
        HARAVAN: 'https://builder.ladipage.com/img/haravan.png',
        SHOPIFY: 'https://builder.ladipage.com/img/shopify.png',
        WORDPRESS: 'https://builder.ladipage.com/img/wordpress.png',
        KIOTVIET: 'https://builder.ladipage.com/img/kiotviet.png',
        GETRESPONSE: 'https://builder.ladipage.com/img/get_response.png',
        ACTIVE_CAMPAIGN: 'https://builder.ladipage.com/img/active_campaign.png',
        HUBSPOT: 'https://builder.ladipage.com/img/hubspot.png',
        INFUSION_SOFT: 'https://builder.ladipage.com/img/infusion_soft.png',
        GOOGLE_SHEET: 'https://builder.ladipage.com/img/google_sheet.png',
        CONVERTKIT: 'https://builder.ladipage.com/img/convertkit.png',
        MAIL_CHIMP: 'https://builder.ladipage.com/img/mail_chimp.png',
        NHANH: 'https://builder.ladipage.com/img/nhanh_vn.png',
        AI_CALL: 'https://w.ladicdn.com/s600x600/5e548427be8067214346f471/4306077-20211011041149.png',

        ZALO: 'https://w.ladicdn.com/5ef1d77068f0886ddcf9ac93/icons8-zalo-20201224031436.svg',
        INSTAGRAM: 'https://w.ladicdn.com/5f9fa8a09b5c4d6ce6e3a77d/instagram-20220119072531.svg',
        WOOCOMMERCE: 'https://cdn.worldvectorlogo.com/logos/woocommerce.svg',
        LADISALES: 'https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales-fw.svg',
        LADIFLOW: 'https://w.ladicdn.com/source/v3/icon_ladiflow_origin.svg',
        LADICHAT: 'https://w.ladicdn.com/source/v3/icon_ladichat_origin.svg',
    },
    DEFAULT_FIELD_SYSTEM: ['full_name', 'first_name', 'last_name', 'phone', 'email', 'tag', 'last_name', 'name', 'alias', 'email',
        'phone_2', 'dob', 'company', 'job_title', 'client_id', 'gender', 'address', 'address_2', 'ward', 'ward_id', 'district',
        'district_id', 'city', 'city_id', 'state', 'country', 'country_id', 'postal_code', 'region', 'locale', 'language',
        'time_zone', 'avatar', 'zalo', 'skype', 'website', 'facebook_url', 'twitter_url', 'linkedIn_url', 'source', 'source_user_id',
        'external_customer_id', 'page_id', 'subscribed_at', 'unsubscribed_at', 'expired_at', 'avatar_color', 'order_sources', 'channel_urls', 'timezone', 'segment',
        'linkedin_url', 'oa_id', 'zalo_uid', 'last_url', 'last_ip', 'score', 'channels', 'is_email_verified', 'email_subscribe_status', 'utm_source', 'utm_campaign',
        'utm_term', 'utm_content', 'utm_medium',
    ],
    REPORT_FLOW: {
        ALL: 'ALL',
        TRIGGER: 'TRIGGER',
        BROADCAST: 'BROADCAST',
    },
    CUSTOMER_ACTIVITY: {
        TYPE: {
            CREATE_ORDER: 'CREATE_ORDER',
            READ_EMAIL: 'READ_EMAIL',
            CLICK_TO_EMAIL_LINK: 'CLICK_TO_EMAIL_LINK',
            SUBMIT_LADIFORM: 'SUBMIT_LADIFORM',
            ORDER_PAYMENT: 'ORDER_PAYMENT',
            ORDER_CANCEL: 'ORDER_CANCEL',
            ORDER_UPDATE: 'ORDER_UPDATE',
            EMAIL_OPEN: 'EMAIL_OPEN',
            EMAIL_CLICK: 'EMAIL_CLICK',
            EMAIL_COMPLAINT: 'EMAIL_COMPLAINT',
            AI_CALL_ACCEPTED: 'AI_CALL_ACCEPTED',
            AI_CALL_BUSY: 'AI_CALL_BUSY',
            AI_CALL_KEYPRESS: 'AI_CALL_KEYPRESS',
            AI_NOT_PICK_UP: 'AI_NOT_PICK_UP',
            LADIPAGE_FORM_SUBMIT: 'LADIPAGE_FORM_SUBMIT',
            FACEBOOK_LEAD_ADS: 'FACEBOOK_LEAD_ADS',
            ADD_VOUCHER: 'ADD_VOUCHER',
        },
    },
    LADIMAIL_DEFAULT: 'no-reply-ladiflow@ladimail.com',
    LADIMAIL_TESTING: 'no-reply@emailtestingflow.com',
    TEMPLATE: {
        TAG: {
            TYPE: {
                EMAIL: {
                    code: 'EMAIL',
                    name: i18n.t('TEMPLATE.EMAIL_TAG'),
                },
                FLOW: {
                    code: 'FLOW',
                    name: i18n.t('TEMPLATE.FLOW_TAG'),
                },
            },
        },
        TOTAL_TYPE: {
            EMAIL: 'EMAIL',
            FLOW: 'FLOW',
        },
        TYPE: {
            EMAIL: {
                code: 'EMAIL',
                name: i18n.t('TEMPLATE.EMAIL'),
            },
            FLOW: {
                code: 'FLOW',
                name: i18n.t('TEMPLATE.FLOW'),
            },
        },
        PKG: {
            STATER: {
                code: 'STATER',
                name: i18n.t('TEMPLATE.FREE'),
            },
            PREMIUM: {
                code: 'PREMIUM',
                name: i18n.t('TEMPLATE.PREMIUM'),
            },
        },
    },

    EMAIL_TRACKING: {
        BOUNCE: {
            code: 'Bounce',
            name: 'Bounce',
        },
        COMPLAINT: {
            code: 'Complaint',
            name: 'Complaint',
        },

    },

    CHANNELS_HOOK: {
        GOOGLE_LEAD_ADS: {
            code: 'GOOGLE_LEAD_ADS',
            name: 'GOOGLE LEAD ADS',
        },
        ELEMENTOR: {
            code: 'ELEMENTOR',
            name: 'ELEMENTOR',
        },
        WP_FORM7: {
            code: 'WPFORM7',
            name: 'WPFORM7',
        },
        WP_FORMS: {
            code: 'WPFORMS',
            name: 'WPFORMS',
        },
        // {
        //     code: 'EDUBIT',
        //     name: 'EDUBIT',
        // },
        // {
        //     code: 'CRMVIET',
        //     name: 'CRM VIỆT',
        // },
    },
    CHANNELS_HOOKS: [
        {
            code: 'GOOGLE_LEAD_ADS',
            name: 'GOOGLE LEAD ADS',
            isShowCustomer: true,
        },
        {
            code: 'ELEMENTOR',
            name: 'ELEMENTOR',
            isShowCustomer: true,
        },
        {
            code: 'WPFORM7',
            name: 'WPFORM7',
            isShowCustomer: true,
        },
        {
            code: 'WPFORMS',
            name: 'WPFORMS',
            isShowCustomer: true,
        },
    ],
    EXPORT_VOUCHER: {
        ON_PAGE: '1',
        SELECTED_VOUCHER: '2',
        ALL_VOUCHER_CAMPAIGN: '3',
    },
    VOUCHERS: {
        STATUS: {
            ALL: {
                code: 'all',
                name: i18n.t('VOUCHERS.SELECT_ALL'),
            },
            ACTIVE: {
                code: true,
                name: i18n.t('VOUCHERS.ACTIVE'),
            },
            INACTIVE: {
                code: false,
                name: i18n.t('VOUCHERS.INACTIVE'),
            },
        },
    },
    TESTER_ID: ['5f7af2abee45222f88b8bb9a', '5e4cdd33b05aa85cedd8243e', '5e548427be8067214346f471', '5f92a2fba5fb857312142d24', '5f9fa8a09b5c4d6ce6e3a77d',
        '61de474c4b97810250215579', '61db98dc6602b90012bda441', '59dad4b1bc6e48fab2041f06', '5f8e4d6cd4e5462e7853c501', '609eb4b9e77c73001366e4fb', '5f7ecfbc4eb72b1e297c472b',
        '5faa3d6d39595d75be63c036', '603c452b48e10f0011be8c90', '5e55f01bbe8067214346f48d', '613ffa731905c800127ee8dc', '6113a638309ca5001238df0b', '60bb4c11e2206c0012ed81cb',
        '602c93e499af3a0012a84f29', '61de474c4b97810250215579', '608790903e489159203f09f7', '5e55e686be8067214346f476', '6228525c2abeb26b38f4925d'],
    LADISHARE: {
        AFFILIATE_STATUS: [
            { value: 'PENDING', name: `${i18n.t('AFFILIATES.STATUS.PENDING')}` },
            { value: 'ACTIVED', name: `${i18n.t('AFFILIATES.STATUS.ACTIVED')}` },
            { value: 'LOCKED', name: `${i18n.t('AFFILIATES.STATUS.LOCKED')}` },
        ],
        AFFILIATE_TYPE: [
            { value: 'PERSONAL', name: `${i18n.t('AFFILIATES.TYPE.PERSONAL')}` },
            { value: 'COMPANY', name: `${i18n.t('AFFILIATES.TYPE.COMPANY')}` },
        ],
        PAYMENT_FOR: [
            { value: 'ORDER', name: `${i18n.t('COMMISSIONS.PAYMENT_FOR.ORDER')}` },
            { value: 'NEW_CUSTOMER', name: `${i18n.t('COMMISSIONS.PAYMENT_FOR.NEW_CUSTOMER')}` },
            { value: 'PER_PRODUCT', name: `${i18n.t('COMMISSIONS.PAYMENT_FOR.PER_PRODUCT')}` },
            { value: 'LEAD', name: `${i18n.t('COMMISSIONS.PAYMENT_FOR.LEAD')}` },
            { value: 'VISITOR', name: `${i18n.t('COMMISSIONS.PAYMENT_FOR.VISITOR')}` },
            { value: 'KPIS', name: `${i18n.t('COMMISSIONS.PAYMENT_FOR.KPIS')}` },
            { value: 'COURSE_PRODUCT', name: 'Khóa học' },
        ],
        PAYMENT_TYPE: [
            { value: 'PERCENT', name: `${i18n.t('COMMISSIONS.PAYMENT_TYPE.PERCENT', { interpolation: { escapeValue: false } })}` },
            { value: 'FIXED', name: `${i18n.t('COMMISSIONS.PAYMENT_TYPE.FIXED')}` },
        ],
        PAYMENT_TERM_TYPE: [
            { value: 'NOW', name: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.NOW')}` },
            { value: 'DAY', name: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.DAY')}` },
            { value: 'MONTH', name: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.MONTH')}` },
            { value: 'YEAR', name: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.YEAR')}` },
        ],
        CURRENCY_VALUES: [
            { value: 'VND', name: `${i18n.t('COMMISSIONS.CURRENCY_VALUES.VND')}` },
            // { value: 'USD', name: `${i18n.t('COMMISSIONS.CURRENCY_VALUES.USD')}` },
        ],
        COMMISSION_STATUS: [
            { value: 'PENDING', name: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.PENDING')}` },
            { value: 'APPROVED', name: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.APPROVED')}` },
            { value: 'INVOICED', name: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.INVOICED')}` },
            { value: 'CANCELED', name: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.CANCELED')}` },
            { value: 'INVOICE_CANCEL', name: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.INVOICE_CANCEL')}` },
        ],
        COMMISSION_TYPE: [
            { value: 'ORDER', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.ORDER')}` },
            { value: 'NEW_CUSTOMER', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.NEW_CUSTOMER')}` },
            { value: 'COUPON', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.COUPON')}` },
            { value: 'LEAD', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.LEAD')}` },
            { value: 'PER_PRODUCT', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.PER_PRODUCT')}` },
            { value: 'CUSTOM', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.CUSTOM')}` },
            { value: 'BONUS', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.BONUS')}` },
            { value: 'KPIS', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.KPIS')}` },
            { value: 'COURSE_PRODUCT', name: `${i18n.t('COMMISSIONS.COMMISSION_TYPE.COURSE_PRODUCT')}` },
        ],
        INVOICE_STATUS: [
            { value: 'PENDING', name: `${i18n.t('INVOICES.INVOICE_STATUS.PENDING')}` },
            { value: 'APPROVED', name: `${i18n.t('INVOICES.INVOICE_STATUS.APPROVED')}` },
            { value: 'PAID', name: `${i18n.t('INVOICES.INVOICE_STATUS.PAID')}` },
            { value: 'CANCELED', name: `${i18n.t('INVOICES.INVOICE_STATUS.CANCELED')}` },
        ],
        INVOICE_REQUEST_STATUS: [
            { value: 'PENDING', name: `${i18n.t('INVOICES.INVOICE_STATUS.PENDING')}` },
            { value: 'APPROVED', name: `${i18n.t('INVOICES.INVOICE_STATUS.APPROVED')}` },
            { value: 'REJECT', name: `${i18n.t('INVOICES.INVOICE_STATUS.REJECT')}` },
            { value: 'CANCELED', name: `${i18n.t('INVOICES.INVOICE_STATUS.CANCELED')}` },
        ],
        STAFF_ROLES: [
            { value: 'MANAGER', name: `${i18n.t('STAFFS.MANAGER')}` },
            { value: 'ACCOUNTANT', name: `${i18n.t('STAFFS.ACCOUNTANT')}` },
            { value: 'SALES', name: `${i18n.t('STAFFS.SALES')}` },
            { value: 'OWNER', name: `${i18n.t('STAFFS.OWNER')}` },
        ],
        APPS_INTEGRATE: [
            { value: 'LADISALES', name: `${i18n.t('INTEGRATE_APPS.LADISALES')}` },
            { value: 'HARAVAN', name: `${i18n.t('INTEGRATE_APPS.HARAVAN')}` },
            { value: 'WORDPRESS', name: `${i18n.t('INTEGRATE_APPS.WORDPRESS')}` },
            { value: 'SAPO', name: `${i18n.t('INTEGRATE_APPS.SAPO')}` },
            { value: 'SHOPIFY', name: `${i18n.t('INTEGRATE_APPS.SHOPIFY')}` },
            { value: 'NHANH', name: `${i18n.t('INTEGRATE_APPS.NHANH')}` },
        ],
        APPS_INTEGRATE_FILTER_COMMISSION: [
            { value: 'LADISALES', name: `${i18n.t('INTEGRATE_APPS.LADISALES')}` },
            { value: 'HARAVAN', name: `${i18n.t('INTEGRATE_APPS.HARAVAN')}` },
            { value: 'WORDPRESS', name: `${i18n.t('INTEGRATE_APPS.WORDPRESS')}` },
            { value: 'SAPO', name: `${i18n.t('INTEGRATE_APPS.SAPO')}` },
            { value: 'SHOPIFY', name: `${i18n.t('INTEGRATE_APPS.SHOPIFY')}` },
            { value: 'NHANH.VN', name: `${i18n.t('INTEGRATE_APPS.NHANH')}` },
            { value: 'GETFLY', name: `${i18n.t('INTEGRATE_APPS.GETFLY')}` },
            { value: 'SDK', name: 'SDK' },
            { value: 'CUSTOM_API', name: 'Custom API' },
        ],
        PAYMENT_INTERVAL_TYPE: [
            { value: 'OFF', name: `${i18n.t('COMMISSIONS.PAYMENT_INTERVAL_TYPE.OFF')}` },
            { value: 'COUNT', name: `${i18n.t('COMMISSIONS.PAYMENT_INTERVAL_TYPE.COUNT')}` },
            { value: 'PURCHASE_TOTAL', name: `${i18n.t('COMMISSIONS.PAYMENT_INTERVAL_TYPE.PURCHASE_TOTAL')}` },
        ],
        SENDER_EMAIL_STATUS: [
            { value: 'PENDING', name: `${i18n.t('STORES.SENDER_EMAIL_STATUS.PENDING')}` },
            { value: 'VERIFIED', name: `${i18n.t('STORES.SENDER_EMAIL_STATUS.VERIFIED')}` },
        ],
    },
};
